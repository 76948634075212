import { Button, Label, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import { Col, Card, CardHeader, CardBody,Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input} from 'reactstrap';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBreedGroups, delBreedGroup } from '../store/dogs/actions';
import "../styles.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import ContentLoader from "react-content-loader";

createTheme('data-table', {
  text: {
    primary: '#000',
    secondary: '#2aa198',
  },
  background: {
    default: '#FFF',
  },
  context: {
    background: '#cb4b16',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

function AllBreedGroups() {

  const { user,logout } = useAuth();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [modal, setModal] = useState(false);
  const [breedGroupId, setId] = useState();
  const [filteredGroupList, setFilteredGroupList] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState([]);
  const [searchParams, setSearchParams] = useState({
    search: '',
    groups: 'All Groups',
  });

  const toggle = (breedGroupId) => {
    setModal(!modal);
    setId(breedGroupId);
  }

  const navigate = useNavigate();
  const { groupList, loading, loadingDelBreed, error, lastUpdated } = useSelector((state) => state.DogsReducer);

  const moveToUploadFile = () => {
    navigate('/dashboard/add-breed-group-file');
  }

  if(!loading && error.message==="Unauthorised Access"){
    logout();
  }

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBreedGroups(year, user.token));
  }, [year]);

  const columns = useMemo(()=>[
  
    {
      name: 'Breed Name',
      selector: row => row.CKCBreedName,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Breed Group',
      selector: row => row.GroupName,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Action',
      ignoreRowClick: true,
      grow: 1,
      cell: row => (
      <Row>
        <Col>
          <Button title='Edit' outline className='manage-event' onClick={() => editBreedGroup(row)}><EditIcon /></Button>
          <Button title='Delete' outline className='manage-event' onClick={() => toggle(row._id)}><DeleteForeverIcon /></Button>
        </Col>
      </Row>
      ),
    },
  ],[],);
  
  const addNewBreedGroup = () => {
    navigate('/dashboard/add-breed-group');
  }

  const editBreedGroup = (row) => {
    navigate('/dashboard/edit-breed-group', { state: { data: row } });
  }

  const deleteBreedGroup = (breedGroupId) => {
    const delData = {
      "breed_group_id": breedGroupId
    };
    dispatch(delBreedGroup(delData, navigate, user.token));
  }

  useEffect(() => {
    setFilteredGroupList(groupList);
    setLastUpdatedDate(lastUpdated);
  }, [groupList]);

  const handleGroupChange = async (e) => {
    const { name, value } = e.target;
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    performSearch({ ...searchParams, [name]: value });
  }

  const performSearch = (params) => {
    if (params.search === '' && params.groups === 'All Groups') {
        setFilteredGroupList(groupList);
    } else if (params.search === '' && params.groups !== 'All Groups') {
        setFilteredGroupList(groupList.filter(obj => obj.GroupName === params.groups));
    } else if (params.search !== '' && params.groups === 'All Groups') {
        setFilteredGroupList(groupList.filter(obj => obj.CKCBreedName?.includes(params.search)));
    } else {
        setFilteredGroupList(groupList.filter(obj => 
            obj.GroupName === params.groups && obj.CKCBreedName?.includes(params.search)
        ));
    }
  };

  const formatDate = (updateDate) => {
    const date = new Date(updateDate);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = monthNames[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    // const hours = String(date.getUTCHours()).padStart(2, '0');
    // const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    // const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  } 

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>All Breeds & Groups <nbsp/> 
        <span class="right-btn">
          <Button color="primary" onClick={() => addNewBreedGroup()}>Add New Breed
          </Button>&nbsp;
          <Button color="primary" onClick={() => moveToUploadFile()}>Upload New File
          </Button>
        </span>
      </p>
      <ToastContainer />
      <div className='page-body'>
        <Card className="my-2" style={{padding:0}} >
        <CardHeader>
            <InputGroup sm={12}>
              <Col sm={2} className='marginRight'>
                <Input id="fileYear" name="fileYear" type="select" onChange={(e) => setYear(e.target.value)} required>
                  {[...Array(2)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                  })}
                </Input>
              </Col>
              <Col sm={3} className='marginRight'>
                <Input sm={6} id="search" name="search" placeholder="Search Breed Name" type="text" onChange={handleGroupChange}/>
              </Col>
              <Col sm={3} className='marginRight'>
                <Input sm={6} id='groups' type='select' name='groups' onChange={handleGroupChange} placeholder='All Groups'>
                  <option>All Groups</option>
                  <option value="Group 1">Group 1 - Sporting Group</option>
                  <option value="Group 2">Group 2 - Hound Group</option>
                  <option value="Group 3">Group 3 - Working Group</option>
                  <option value="Group 4">Group 4 - Terrier Group</option>
                  <option value="Group 5">Group 5 - Toy Group</option>
                  <option value="Group 6">Group 6 - Non-Sporting Group</option>
                  <option value="Group 7">Group 7 - Herding Group</option>
                </Input>
              </Col>
              <Col sm={3} className='marginRight'>
                  <Label sm={10}>Last Updated - {lastUpdatedDate != null ? formatDate(lastUpdatedDate.created_at) : ''}</Label>
              </Col>
            </InputGroup>
          </CardHeader>
          <CardBody >
            {loading ? (
              <div className="loader" >
                <ContentLoader 
                  speed={1}
                  width={'100%'}
                  height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                  viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <rect
                      key={index}
                      x="0"
                      y={(70 + 4) * index}
                      rx="5"
                      ry="5"
                      width="100%"
                      height="70"
                    />
                  ))}
                </ContentLoader>
              </div>
              ) : (
              <DataTable
                columns={columns}
                data={filteredGroupList}
                theme=""
                pagination
                paginationRowsPerPageOptions={[50,100]}
                paginationPerPage={50}
                highlightOnHover
                fixedHeader
                defaultSortAsc={false}
                defaultSortFieldId={"date"}
                sortIcon={<FilterListIcon />}
              />
            )}
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Entry</ModalHeader>
        <ModalBody>
          Are you sure you want to Delete this Breed?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteBreedGroup(breedGroupId)}>
            {loadingDelBreed ? ('Deleting...') : ('Yes, Delete')}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AllBreedGroups;
