import { Button } from 'reactstrap';
import React from 'react';
import { Col, Alert, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { addBreedGroup } from '../../store/dogs/actions';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from '../../hooks/useAuth';

function AddNewBreedGroup() {

  const { loading ,error} = useSelector((state) => state.DogsReducer);
  const { user, logout } = useAuth();

  let dispatch = useDispatch();
  const navigate = useNavigate();

  if(!loading && error.message==="Unauthorised Access"){
    logout();
  }

  const addNewBreed = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const breedData = {
      group: data.get("group"),
      breed: data.get("breed"),
      breedYear: data.get("breedYear"),
    }
    dispatch(addBreedGroup(breedData, user.token, navigate));
  }

  const cancelAdd = () => {
    navigate('/dashboard/allBreedsTable');
  }

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Add New Breed Group</p>
      <div className='page-body' >
        <CardBody>
          <Form onSubmit={addNewBreed}>
            <FormGroup row>
              <Label for="group" sm={2}> Select Group </Label>
              <Col sm={10}>
                <Input id="group" name="group" type="select" required>
                  <option value="" disabled>Select Group</option>
                  <option value="Group 1">Group 1</option>
                  <option value="Group 2">Group 2</option>
                  <option value="Group 3">Group 3</option>
                  <option value="Group 4">Group 4</option>
                  <option value="Group 5">Group 5</option>
                  <option value="Group 6">Group 6</option>
                  <option value="Group 7">Group 7</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="breed" sm={2}> Breed Name </Label>
              <Col sm={10}>
                <Input id="breed" name="breed" placeholder="Enter Breed Name" type="text" required/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="breedYear" sm={2}> Select Year </Label>
              <Col sm={10}>
                <Input id="breedYear" name="breedYear" type="select" required>
                  {[...Array(2)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ offset: 2, size: 10}}>
                <Button color='primary' outline type='submit'>
                  {loading ? ('Please wait...') : ('Add Breed')}
                </Button>{' '}
                {loading ? (
                <Alert color="primary">
                  We are adding breed. It can take few minutes...
                </Alert>
                ) : ('')}
                <Button color="secondary" onClick={cancelAdd}>
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </div>
    </div>
  );
}

export default AddNewBreedGroup;
