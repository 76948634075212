import { takeLatest, put, call } from "redux-saga/effects";

import { 
  GET_DOGS,
  DEL_DOG,
  EDIT_DOG,
  GET_BREED_GROUP,
  ADD_BREED_GROUP,
  EDIT_BREED_GROUP,
  DEL_BREED_GROUP,
  UPLOAD_GROUP_FILE
} from "./actionTypes";

import {
  getDogsSuccess,
  getDogsFail,
  delDogSuccess,
  delDogFailure,
  editDogSuccess,
  editDogFailure,
  getBreedGroupsSuccess,
  getBreedGroupsFail,
  addBreedGroupSuccess,
  addBreedGroupFail,
  editBreedGroupSuccess,
  editBreedGroupFail,
  delBreedGroupSuccess,
  delBreedGroupFailure,
  uploadGroupFileSuccess,
  uploadGroupFileFailure
  
} from "./actions";

import { getDogs,delDog,editDog,getBreedGroups,addBreedGroup,editBreedGroup,delBreedGroup,uploadBreedGroup} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* onGetDogs({token}) {

  try {
    const response = yield call(getDogs,token);
    yield put(getDogsSuccess(response.data));
  } catch (error) {
    yield put(getDogsFail(error.response.data));
  }
}

function* onDelDog({ payload: data,navigate,token }) {
  
  try {
    const response = yield call(delDog,data,token);
    toast(response.message);
    if(response.statusCode===200){
      navigate(0);
    }
    yield put(delDogSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delDogFailure(error.response));
  }
}

function* onEditDog({ payload: data,navigate,token }) {

  try {
    const response = yield call(editDog,data,token);
    toast(response.message);
    if(response.statusCode===200){
      navigate("/dashboard/dogs");
    }
    yield put(editDogSuccess(response.data));
  } catch (error) {
    toast(error.response.data.message);
    yield put(editDogFailure(error.response));
  }
}

function* onGetBreedGroups({ payload: year, token}) {

  try {
    const response = yield call(getBreedGroups, year, token);
    yield put(getBreedGroupsSuccess(response.data));
  } catch (error) {
    yield put(getBreedGroupsFail(error.response.data));
  }
}

function* onAddBreedGroup({payload: data, navigate, token}) {

  try {
    const response = yield call(addBreedGroup, data, token);
    if(response.statusCode === 200){
      toast(response.data);
      navigate("/dashboard/allBreedsTable");
    }
    yield put(addBreedGroupSuccess(response.data));
  } catch (error) {
    yield put(addBreedGroupFail(error.response));
  }
}

function* onEditBreedGroup({payload: data, navigate, token}) {

  try {
    const response = yield call(editBreedGroup, data, token);
    if(response.statusCode === 200){
      toast(response.data);
      yield call(delay, 1000);
      navigate("/dashboard/allBreedsTable");
    }
    yield put(editBreedGroupSuccess(response.data));
  } catch (error) {
    yield put(editBreedGroupFail(error.response));
  }
}

function* onDeleteBreedGroup({ payload: data,navigate,token }) {

  try {
    const response = yield call(delBreedGroup,data,token);
    toast(response.data);
    yield call(delay, 1000);
    if(response.statusCode === 200){
      navigate(0);
    }
    yield put(delBreedGroupSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delBreedGroupFailure(error.response));
  }
}

function* onUploadBreedGroup({ payload: data,navigate,token }) {

  try {
    const response = yield call(uploadBreedGroup,data,token);
    if(response.statusCode === 200){
      navigate("/dashboard/allBreedsTable");
    }
    yield put(uploadGroupFileSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(uploadGroupFileFailure(error.response));
  }
}


function* DogsSaga() {

  yield takeLatest(GET_DOGS, onGetDogs);
  yield takeLatest(DEL_DOG, onDelDog);
  yield takeLatest(EDIT_DOG, onEditDog);
  yield takeLatest(GET_BREED_GROUP, onGetBreedGroups);
  yield takeLatest(ADD_BREED_GROUP, onAddBreedGroup);
  yield takeLatest(EDIT_BREED_GROUP, onEditBreedGroup);
  yield takeLatest(DEL_BREED_GROUP, onDeleteBreedGroup);
  yield takeLatest(UPLOAD_GROUP_FILE, onUploadBreedGroup);

}

export default DogsSaga;
