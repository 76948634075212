import {
  GET_DOGS,
  GET_DOGS_SUCCESS,
  GET_DOGS_FAIL,
  DEL_DOG,
  DEL_DOG_FAILURE,
  DEL_DOG_SUCCESS,
  EDIT_DOG,
  EDIT_DOG_SUCCESS,
  EDIT_DOG_FAILURE,
  GET_BREED_GROUP,
  GET_BREED_GROUP_SUCCESS,
  GET_BREED_GROUP_FAIL,
  ADD_BREED_GROUP,
  ADD_BREED_GROUP_SUCCESS,
  ADD_BREED_GROUP_FAIL,
  EDIT_BREED_GROUP,
  EDIT_BREED_GROUP_SUCCESS,
  EDIT_BREED_GROUP_FAIL,
  UPLOAD_GROUP_FILE,
  UPLOAD_GROUP_FILE_SUCCESS,
  UPLOAD_GROUP_FILE_FAIL
} from "./actionTypes";

const initialState = {
  dogList: [],
  loading: false,
  error: {
    message: "",
    code:"",
  },
  delDogResponse: {},
  loadingDelDog: false,

  editDogResponse: {},
  loadingEditDog: false,

  groupList: [],
  addBreedResponse: {},
  editBreedResponse: {},
  loadingEditBreed: false,

  uploadBreedResponse: {},
  loadingAddFile: false,
  lastUpdated: []
};

const DogsReducer = (state = initialState, action) => {


  switch (action.type) {

    case GET_DOGS:
      state = { ...state, loading: true };
      break;
    case GET_DOGS_SUCCESS:
      state = { ...state, dogList: action.payload.dogList, loading: false };
      break;
    case GET_DOGS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.message
                },
        loading: false,
      };
      break;
    case DEL_DOG:
      state = { ...state, loadingDelDog: true };
      break;
    case DEL_DOG_SUCCESS:
      state = { ...state, delDogResponse: action.payload, loadingDelDog: false };
      break;
    case DEL_DOG_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.message,
        },
        loadingDelDog: false,
      };
      break;
    case EDIT_DOG:
      state = { ...state, loadingEditDog: true };
      break;
    case EDIT_DOG_SUCCESS:
      state = { ...state, editDogResponse: action.payload, loadingEditDog: false };
      break;
    case EDIT_DOG_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.message,
        },
        loadingEditDog: false,
      };
      break;
    case GET_BREED_GROUP:
      state = { ...state, loading: true };
      break;
    case GET_BREED_GROUP_SUCCESS:
      state = { ...state,
        groupList: action.payload.groupList,
        lastUpdated: action.payload.lastUpdated, 
        loading: false 
      };
      break;
    case GET_BREED_GROUP_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.message
        },
        loading: false,
      };
      break;
    case ADD_BREED_GROUP:
      state = { ...state, loading: true };
      break;
    case ADD_BREED_GROUP_SUCCESS:
      state = { ...state, 
        addBreedResponse: action.payload, 
        loading: false 
      };
      break;
    case ADD_BREED_GROUP_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload
        },
        loading: false,
      };
      break;
    case EDIT_BREED_GROUP:
        state = { ...state, loadingEditBreed: true };
        break;
    case EDIT_BREED_GROUP_SUCCESS:
        state = { ...state, editBreedResponse: action.payload, loadingEditBreed: false };
        break;
    case EDIT_BREED_GROUP_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload.message,
          },
          loadingEditBreed: false,
        };
        break;
    case UPLOAD_GROUP_FILE:
        state = { ...state, loadingAddFile: true };
        break;
    case UPLOAD_GROUP_FILE_SUCCESS:
        state = { ...state, uploadBreedResponse: action.payload, loadingAddFile: false };
        break;
    case UPLOAD_GROUP_FILE_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload.message,
          },
          loadingAddFile: false,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DogsReducer;
