import { Button } from 'reactstrap';
import React from 'react';
import { Col, Alert, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { activateAccount } from '../../store/users/actions';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';

function AddNewUser() {

  const { loadingAddUser ,error} = useSelector((state) => state.UsersReducer);
  const { user, logout } = useAuth();

  let dispatch = useDispatch();
  const navigate = useNavigate();

  if(!loadingAddUser&&error.message==="Unauthorised Access"){
    logout();
  }

  const addNewUser = async (user) => {
    user.preventDefault();
    const data = new FormData(user.currentTarget);
    const accountData = {
      "email": data.get("userEmail"),
      "name": data.get("userName"),
      "role": data.get("userRole"),
      "password": "123456",
    };
    console.log(accountData);
    dispatch(activateAccount(accountData, navigate, user.token));
  };

  const cancelAdd = () => {
    navigate('/dashboard/allUsers');
  }

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Add New User</p>
      <div className='page-body' >
        <CardBody>
          <Form onSubmit={addNewUser}>
            <FormGroup row>
              <Label for="eventName" sm={2}> Name </Label>
              <Col sm={10}>
                <Input id="userName" name="userName" placeholder="Enter Name" type="text" required/>
              </Col>
            </FormGroup>
            <FormGroup row>
            <Label for="eventName" sm={2}> Email </Label>
              <Col sm={10}>
                <Input id="userEmail" name="userEmail" placeholder="Enter Email" type="text" required/>
              </Col>
            </FormGroup>
            <FormGroup row>
            <Label for="userRole" sm={2}> Role </Label>
              <Col sm={10}>
                <Input id="userRole" name="userRole" type="select" required>
                  <option value="" disabled>Select role</option>
                  <option value="marketing user">Marketing User</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ offset: 2, size: 10}}>
                <Button color='primary' outline type='submit'>
                  {loadingAddUser ? ('Please wait...') : ('Add User')}
                </Button>{' '}
                {loadingAddUser ? (
                  <Alert color="primary">
                    We are creating profile. It can take few minutes...
                  </Alert>
                  ) : ('')}
                  <Button color="secondary" onClick={cancelAdd}>
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </div>
    </div>
  );
}

export default AddNewUser;
