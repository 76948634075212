import { Button } from 'reactstrap';
import React from 'react';
import { Col, Card, CardHeader, CardBody, Table, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editDog } from '../../store/dogs/actions';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

function EditDogData() {
  const { loadingEditDog } = useSelector((state) => state.DogsReducer);
  const { user, logout } = useAuth();

  const location = useLocation();

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const dogData = location.state.data;


  const editDogData = async (event) => {

    event.preventDefault();


    const data = new FormData(event.currentTarget);

    const editDogData = {
      "dog_id": dogData.dog_id,
      "name": data.get("dogName"),
      "title": data.get("dogNameTitle"),
      "breed": data.get("breed"),
      "breed_size": data.get("breedSize"),
      "breed_variety": data.get("breedVariety"),
      "gender": data.get("gender")

    };
    dispatch(editDog(editDogData, user.token, navigate));

  };

  const cancelEdit = () => {
    navigate('/dashboard/dogs');
  }

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>Edit Dog - {dogData.dog_number}</p>

      <div className='page-body' >

        <CardBody>

          <Form onSubmit={editDogData}>



            <FormGroup row>
              <Label
                for="dogName"
                sm={2}
              >
                Dog Name
              </Label>
              <Col sm={10}>
                <Input
                  id="dogName"
                  name="dogName"
                  placeholder="Dog name here"
                  defaultValue={dogData.name}
                  type="text"
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                for="dogNameTitle"
                sm={2}
              >
                Dog Name With Title
              </Label>
              <Col sm={10}>
                <Input
                  id="dogNameTitle"
                  name="dogNameTitle"
                  placeholder="Dog name with title here"
                  type="text"
                  defaultValue={dogData.title}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                for="gender"
                sm={2}
              >
                Dog Gender
              </Label>
              <Col sm={10}>
                <Input
                  id="gender"
                  name="gender"
                  type="select"
                  defaultValue={dogData.gender}
                  required
                >
                  <option>
                    Male
                  </option>
                  <option>
                    Female
                  </option>

                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                for="breed"
                sm={2}
              >
                Dog Breed
              </Label>
              <Col sm={10}>
                <Input
                  id="breed"
                  name="breed"
                  placeholder="Dog breed here"
                  defaultValue={dogData.breed}
                  type="text"
                  required
                />
              </Col>
            </FormGroup>


            <FormGroup row>
              <Label
                for="breedSize"
                sm={2}
              >
                Dog Breed Size
              </Label>
              <Col sm={10}>
                <Input
                  id="breedSize"
                  name="breedSize"
                  placeholder="Dog breed size here"
                  defaultValue={dogData.breed_size}
                  type="text"
                />
              </Col>
            </FormGroup>


            <FormGroup row>
              <Label
                for="breedVariety"
                sm={2}
              >
                Dog Breed Variety
              </Label>
              <Col sm={10}>
                <Input
                  id="breedVariety"
                  name="breedVariety"
                  placeholder="Dog breed variety here"
                  defaultValue={dogData.breed_variety}
                  type="text"
                />
              </Col>
            </FormGroup>

            <FormGroup
              row
            >
              <Col
                sm={{
                  offset: 2,
                  size: 10
                }}
              >

                <Button color='primary' outline type='submit'>
                  {loadingEditDog ? (
                    'Saving...'
                  ) : (
                    'Save Changes'
                  )}
                </Button>{' '}
                <Button color="secondary" onClick={cancelEdit}>
                  Cancel
                </Button>
                <ToastContainer />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </div>
    </div>
  );
}

export default EditDogData;
