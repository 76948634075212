import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  DEL_USER,
  DEL_USER_SUCCESS,
  DEL_USER_FAILURE
} from "./actionTypes";

export const getUsers= (token) => {
  return {
    type: GET_USERS,
    token:token
  };
};

export const getUsersSuccess = (userList) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: userList,
  };
};

export const getUsersFail = (error) => {
  return {
    type:GET_USERS_FAIL,
    payload: error,
  };
};

export const activateAccount = (data,navigate) => {
  return {
    type: ACTIVATE_ACCOUNT,
    payload: data,
    navigate:navigate,
  };
};

export const activateAccountSuccess = (activateAccountResponse) => {
  return {
    type: ACTIVATE_ACCOUNT_SUCCESS,
    payload:activateAccountResponse,
  };
};

export const activateAccountFailure = (error) => {
  return {
    type: ACTIVATE_ACCOUNT_FAILURE,
    payload:error,
  };
};

export const resetUserPassword = (data,navigate) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: data,
    navigate:navigate,
  };
};

export const resetUserPasswordSuccess = (resetUserPasswordResponse) => {
  return {
    type: RESET_USER_PASSWORD_SUCCESS,
    payload:resetUserPasswordResponse,
  };
};

export const resetUserPasswordFailure = (error) => {
  return {
    type: RESET_USER_PASSWORD_FAILURE,
    payload:error,
  };
};

export const delUser = (data,navigate,token) => {
  return {
    type: DEL_USER,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delUserSuccess = (delUserResponse) => {
  return {
    type:DEL_USER_SUCCESS,
    payload:delUserResponse,
  };
};

export const delUserFailure = (error) => {
  return {
    type: DEL_USER_FAILURE,
    payload:error,
  };
};
