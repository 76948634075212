import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import { Col,Card,CardHeader,CardBody,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEvents,addEvent,getEventAttendee } from '../store/events/actions';
import "../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';

import { CSVLink, CSVDownload } from "react-csv";

import { useAuth } from '../hooks/useAuth';
import Grid from "@mui/material/Grid";

import {Routes, Route, useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import ContentLoader from "react-content-loader";

createTheme('data-table', {
  text: {
    primary: '#000',
    secondary: '#2aa198',
  },
  background: {
    default: '#FFF',
  },
  context: {
    background: '#cb4b16',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

function EventAttendees() {

 
  const navigate = useNavigate();
  const { attendeeList, loadingGetEventAttendee } = useSelector((state) => state.EventsReducer);
  const location = useLocation();
  const { user ,logout} = useAuth();


  const columns = useMemo(()=>[
    {
      name: 'Class or Stake',
      selector: row => row.class,
      sortable: true,
      wrap:true,
      grow:2

    },
    {
      name: 'Division',
      selector: row => row.division,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Level',
      selector: row => row.level,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Score1',
      selector: row => row.score,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Pass or Fail',
      selector: row => row.result,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Judge',
      selector: row => row.judge,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Status Reason',
      selector: row => row.status,
      sortable: true,
      wrap:true,
      grow:2
    }, 
    {
      name: 'Event No.(Event)',
      selector: row => row.event_data.event_number,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Start Date',
      selector: row => row.start_date,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'End Date',
      selector: row => row.end_date,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Event Club',
      selector: row => row.event_data.event_club,
      sortable: true,
      wrap:true,
      grow:3
    },
    {
      name: 'Venue',
      selector: row => row.event_data.venue,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Event Type',
      selector: row => row.event_data.event_type,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'CKC Dog Number',
      selector: row => row.dog_data.dog_number,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Name(Dog)',
      selector: row => row.dog_data.dog_name,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Dog Name with Title(Dog)',
      selector: row => row.dog_data.dog_title,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Gender(Dog)',
      selector: row => row.dog_data.gender,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Breed(Dog)',
      selector: row => row.dog_data.breed,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Breed Size(Dog)',
      selector: row => row.dog_data.breed_size,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Breed Variety',
      selector: row => row.dog_data.breed_variety,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Full Name(Contact)',
      selector: row => row.contact_data.name,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'CKC #(Contact)',
      selector: row => row.contact_data.contact_number,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Email(Contact)',
      selector: row => row.contact_data.email,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Address 1: Street 1(Contact)',
      selector: row => row.contact_data.address_street_1,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Address 1: Street 2(Contact)',
      selector: row => row.contact_data.address_street_2,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Address 1: City(Contact)',
      selector: row => row.contact_data.city,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Address 1: State/Province(Contact)',
      selector: row => row.contact_data.area,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Address 1: ZIP/Postal Code(Contact)',
      selector: row => row.contact_data.zip_code,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Address 1: Country/Region(Contact)',
      selector: row => row.contact_data.region,
      sortable: true,
      wrap:true,
      grow:2
    }
  ],[],);
  
  




  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventAttendee(location.state.eventId,user.token));

  }, []);

 
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <p className='page-heading'>Event Attendees <nbsp/> 
      
      </p>

     
      <ToastContainer />

      <div className='page-body'>


      <Card
  className="my-2"
  style={{padding:0}}
 
>
<CardHeader>{location.state.event.name +" - "+location.state.event.event_category+" - "+location.state.event.club+" ( "+location.state.event.start_date+" to "+location.state.event.end_date+" ) "}</CardHeader>

<CardBody className='event-card-body'>

        {loadingGetEventAttendee ? (
          <div className="loader" >
            <ContentLoader 
              speed={1}
              width={'100%'}
              height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
              viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {Array.from({ length: 10 }, (_, index) => (
                <rect
                  key={index}
                  x="0"
                  y={(70 + 4) * index}
                  rx="5"
                  ry="5"
                  width="100%"
                  height="70"
                />
              ))}
            </ContentLoader>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={attendeeList}
            theme=""
            pagination
            paginationRowsPerPageOptions={[50,100]}
            paginationPerPage={50}
            highlightOnHover
            fixedHeader
            sortIcon={<FilterListIcon />}
          />
        )}
</CardBody>
      </Card>
      </div>


    </div>
  );
}

export default EventAttendees;
