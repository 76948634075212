import DataTable, { createTheme } from 'react-data-table-component';
import React, { useMemo } from 'react';
import { Row, Col, Input, InputGroup, Button } from 'reactstrap';
import logo from '../assets/ckc-logo.png';
import { useSearchParams,useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverallResult, downloadDigitalCertificate } from '../store/events/actions';
import { Circles } from "react-loader-spinner";
import { CSVLink, CSVDownload } from "react-csv";
import { red } from '@mui/material/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from '../hooks/useAuth';
import {  getPostingDates } from '../store/postingDates/actions';
import { getGroups,getBreedsInGroup } from '../helpers/backend_helper';
import ContentLoader from "react-content-loader";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { toast } from 'react-toastify';

export const PublicRanking = () => {
  const { user ,logout} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filteredRankingList, setFilteredRankingList] = useState([]);
  var rankingForCSVAgilityHerding = [], rankingForCSV = [], rankingForCSVAgilityHerdingBreedWise = [], rankingForCSVAgilityHerdingGroupWise = [];
  const { overallRankingList, loadingGetOverallResult, loadingDigitalCertificate, certificateLink } = useSelector((state) => state.EventsReducer);
  const [breedList, setBreedList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [eventCat, setEventCat] = useState(searchParams.get("type"));
  const [breed_group, setBreedGroup] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [newLoading, setNewLoading] = useState(true);
  const [downloadCerticatePayload, setDownloadCerticatePayload]=useState({});
  let new_ranking_list, filter_ranking_list;
  const { postingDatesList } = useSelector((state) => state.PostingDatesReducer);
  const [loadingRows, setLoadingRows] = useState({});
  const [postingDates, setPostingDates] = useState([]);
  const [certificateAction, setCertificateAction] = useState(false);

  let dispatch = useDispatch();
  let allBreads = [];
  let allClasses = [];
  let beagleClasses = ["Females 13", "Females 15", "Males 13", "Males 15"];
  var no_of_export_breed_wise = 5;

  useEffect(() => {
    setNewLoading(true);
    setFilteredRankingList([]);
    const startEpoch = new Date("Jan 01," + year + " 00:00:00 GMT").getTime();
    const endEpoch = new Date("Dec 31," + year + " 00:00:00 GMT").getTime();
    dispatch(getOverallResult(eventCat + "&start_year=" + startEpoch + "&end_year=" + endEpoch));
    dispatch(getPostingDates(user?user.token:""));
  }, [year, eventCat]);

  useEffect(() => {
    getAllGroups();
  }, []);

  const getAllGroups = async () =>{
    let response= await getGroups();
    setAllGroups(response.group_data);
  }
  
  useEffect(() => {
    setFilteredRankingList(calculateRank(overallRankingList));
    let breedsElement = document.getElementById("breeds");
    let groupsElement = document.getElementById("groups");
    let classElement = document.getElementById("classOrStake");

    groupsElement.value = "Select Group";
    if (breedsElement !== null)
      breedsElement.value = "All Breeds";
    if (classElement !== null)
      classElement.value = "All";

      // if(eventCat==="Beagle"){
      //   setFilteredRankingList(calculateRank(overallRankingList.filter(obj => {
      //     return obj.class === 'Males 15"';
      //   })));
      //   classElement.value = 'Males 15"';
      // }else  if(eventCat==="Pointing - Field Trial"){
      //   setFilteredRankingList(calculateRank(overallRankingList.filter(obj => {
      //     return obj.class === 'Open Shooting Dog';
      //   })));
      //   classElement.value = 'Open Shooting Dog';
      // }
      // else  if(eventCat==="Retriever Field Trial"){
      //   setFilteredRankingList(calculateRank(overallRankingList.filter(obj => {
      //     return obj.class === 'Open All-Age';
      //   })));
      //   classElement.value = 'Open All-Age';
      // }
      // else  if(eventCat==="Spaniel Field Trial"){
      //   setFilteredRankingList(calculateRank(overallRankingList.filter(obj => {
      //     return obj.class === 'Open All-Age';
      //   })));
      //   classElement.value = 'Open All-Age';
      // }
    setNewLoading(false);
    setBreedGroup("");

   
  }, [overallRankingList]);

  useEffect(() => {
    const filteredDates = postingDatesList.filter(obj => obj.year === year);
    setPostingDates(filteredDates);
    if (filteredDates[0] && filteredDates[0].display) {
      setCertificateAction(filteredDates[0].display);
    } else {
      setCertificateAction(false);
    }
  }, [postingDatesList, year]);
  

  useEffect(() => {
    setFilteredRankingList(calculateRank(overallRankingList.filter((el) => {
      return breedList.some((f) => {
        return f.CKCBreedName === el.new_breed;
      });
    })));
  }, [breedList]);

  const navigate = useNavigate();

  const viewDogResult=(row)=>{
    navigate('/dog-result?dog_number='+row.dog_number);

  }

  useEffect(() => {
    if(certificateAction){
      if(downloadCerticatePayload?.isDownloading){
        let certificateTitle = '';
        switch (eventCat) {
          case "Agility":
            certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
            break;
          case "Beagle":
            certificateTitle = "CKC TOP FIELD BEAGLE";
            break;
          case "Herding":
            certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
            break;
          case "Lure Coursing":
            certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
            break;
          case "Pointing - Field Trial":
            certificateTitle = "CKC TOP POINTING DOG";
            break;
          case "Retriever Field Trial":
            certificateTitle = "CKC TOP RETRIEVING DOG";
            break;
          case "Spaniel Field Trial":
            certificateTitle = "CKC TOP FIELD SPANIEL";
            break;
          default:
            certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
            break;
        }
        const data = {
          eventType: eventCat,
          eventYear: downloadCerticatePayload.startYear,
          breedType: breed_group,
          ranking: downloadCerticatePayload.ranking,
          dogNumber: downloadCerticatePayload.dogNumber,
          resultId: downloadCerticatePayload.resultId,
        }
        dispatch(downloadDigitalCertificate(data));
        setDownloadCerticatePayload({});
      }
    } else{
      toast('Some error occured in downloading certificate!!');
    }
  }, [downloadCerticatePayload?.isDownloading]);

  let generateDigitalCertificate = async (row)=>{
    dispatch(getPostingDates(user?user.token:""));
    let certificateYear = '';
    if(row.start_date !== ''){
      var date = new Date(row.start_date);
      certificateYear = date.getFullYear(); 
    }
    else{
      certificateYear = year;
    }

    const data = {
      startYear: certificateYear,
      ranking: row.new_rank,
      dogNumber: row.dog_number,
      resultId: row.result_id,
      isDownloading:true
    }
    setDownloadCerticatePayload(data);
    setLoadingRows((prevLoadingRows) => ({
      ...prevLoadingRows,
      [row.result_id]: true,
    }));
  }

  useEffect(() => {
    if (!loadingDigitalCertificate) {
      // Reset all loading states to false when loadingDigitalCertificate becomes false
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = { ...prevLoadingRows };
        for (let key in newLoadingRows) {
          newLoadingRows[key] = false;
        }
        return newLoadingRows;
      });
      if(certificateLink){
        window.open(certificateLink, '_blank');
      }
    }
  }, [loadingDigitalCertificate, certificateLink]);

  const formatDogName = (row) => {
    let string = row.dog_title;
    let arrayOfString = string.includes(" ") ? string.split(" ") : [string];
    let characterLength = 0;
    let lineIndex = 0;
    let line = [];
    let totalValues = arrayOfString.length;
    let formattedText = '';
    if (totalValues > 1) {
      arrayOfString.forEach((value, key) => {
          let valCharLength = value.length + 1; // add 1 for the space after each word
          characterLength += valCharLength;
          
          if (characterLength <= 31) {
              line[lineIndex] = line[lineIndex] || [];
              line[lineIndex].push(value);
          } else {
              lineIndex++;
              if (key === totalValues - 1) { // if it's the last element in the array
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(line[lineIndex - 1].pop()); // Get element from last line
                  line[lineIndex].push(value);
              } else {
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(value);
              }
              characterLength = valCharLength; // reset character length for the new line
          }
      });

      // Join lines with <br> tag
      formattedText = line.map(line => line.join(' ')).join("\n");
    }
    else{
      formattedText = arrayOfString;
    }
    return formattedText;
  };

  allBreads = [...new Set(overallRankingList.map((item) => item.new_breed))];
  allClasses = [...new Set(overallRankingList.map((item) => item.class))];

  const columnsAgility = useMemo(() => [

    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
      grow: 2
    },

    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
    {
      name: 'Av. Score',
      selector: row => row.average_score,
      center: true,
      wrap: true,
    },
    {
      id: "totalPoints",
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="5">
            {loadingRows[row.result_id] ? (
              <div className="loader">
                <Circles
                  height="100"
                  width="100"
                  color="#696b6d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  timeout={3000} // 3 secs
                />
              </div>
            ) : (
              ( certificateAction ) ? (
                <Button title='Download Certificate' color='primary' outline className='manage-event' onClick={() => generateDigitalCertificate(row)}>
                  <CardMembershipIcon/>
                </Button>
              ) : (
                <Button title='Certificate Unavailable' color='secondary' outline className='manage-event' disabled={true}>
                  <CardMembershipIcon/>
                </Button>
              )
            )}
          </Col>
        </Row>
      ),
    },

  ], [loadingRows, certificateAction]);

  const columnsBeagle = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>
      ),
    },
    {
      name: 'Class/Stake',
      selector: row => row.class,
      wrap: true,
      grow: 2
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
      grow: 2
    },
    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
    {
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="5">
            {loadingRows[row.result_id] ? (
              <div className="loader">
                <Circles
                  height="100"
                  width="100"
                  color="#696b6d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  timeout={3000} // 3 secs
                />
              </div>
            ) : (
              ( certificateAction ) ? (
                <Button title='Download Certificate' color='primary' outline className='manage-event' onClick={() => generateDigitalCertificate(row)}>
                  <CardMembershipIcon/>
                </Button>
              ) : (
                <Button title='Certificate Unavailable' color='secondary' outline className='manage-event' disabled={true}>
                  <CardMembershipIcon/>
                </Button>
              )
            )}
          </Col>
        </Row>
      ),
    },

  ], [loadingRows, certificateAction]);

  const columnsLureCoursing = useMemo(() => [

    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
      grow: 2
    },

    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
 
    {
      id: "totalPoints",
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="5">
            {loadingRows[row.result_id] ? (
              <div className="loader">
                <Circles
                  height="100"
                  width="100"
                  color="#696b6d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  timeout={3000} // 3 secs
                />
              </div>
            ) : (
              ( certificateAction ) ? (
                <Button title='Download Certificate' color='primary' outline className='manage-event' onClick={() => generateDigitalCertificate(row)}>
                  <CardMembershipIcon/>
                </Button>
              ) : (
                <Button title='Certificate Unavailable' color='secondary' outline className='manage-event' disabled={true}>
                  <CardMembershipIcon/>
                </Button>
              )
            )}
          </Col>
        </Row>
      ),
    },

  ], [loadingRows, certificateAction]);

  function calculateRank(results_array) {
    // Sort the array based on score
    results_array.sort((a, b) => b.total_points - a.total_points);

    let rank = 1;
    let lastScore = null;

    // Assign a rank to each object based on its position in the sorted array
    results_array.forEach((item, index) => {
      if (item.total_points !== lastScore) {
        rank = index + 1;
      }
      item.new_rank = rank;
      lastScore = item.total_points;
    });
    return results_array;
  }

  function handleBreedChange(e) {
    console.log(e.target.value);
    let element = document.getElementById("groups");
    element.value = "Select Group";
    setBreedGroup(e.target.value);
    if (e.target.value === "All Breeds" || e.target.value === "Select") {

      setFilteredRankingList(calculateRank(overallRankingList));
    } else {
      setFilteredRankingList(calculateRank(overallRankingList.filter(obj => {
        return obj.new_breed === e.target.value;
      })));
    }
  }

  const handleGroupChange = async (e) => {
    console.log(e.target.value);
    let breedsElement = document.getElementById("breeds");
    let classElement = document.getElementById("classOrStake");

    if (e.target.value === "Select Group") {
      if (breedsElement !== null)
        breedsElement.value = "All Breeds";
      if (classElement !== null)
        classElement.value = "All";
      setFilteredRankingList(calculateRank(overallRankingList));
      setBreedGroup("");
    } else {
      if (breedsElement !== null)
        breedsElement.value = "Select";
      if (classElement !== null)
        classElement.value = "All";
   

      let response= await getBreedsInGroup(e.target.value);
      console.log("in res",response);
      setBreedList(response.breed_data);
      setBreedGroup("Group " + e.target.value);
    }
  }

  function handleClassChange(e) {
    console.log(e.target.value);
    let element = document.getElementById("groups");
    element.value = "Select Group";
    setBreedGroup(e.target.value);
    if (e.target.value === "All") {
      setFilteredRankingList(calculateRank(overallRankingList));
    } else {
      setFilteredRankingList(calculateRank(overallRankingList.filter(obj => {
        return obj.class === e.target.value;
      })));
    }
  }

  function getGroupName(breed_name) {
    let temp = allGroups.filter(obj => {
      return obj?.CKCBreedName?.toLowerCase() === breed_name?.toLowerCase();
    });
    if (temp.length > 0) {
      return temp[0].GroupName;
    } else {
      return "Unrecognized";
    }
  }
  if (overallRankingList.length > 0) {

    for (var i = 0; i < overallRankingList.length; i++) {
      let item = overallRankingList[i];
      if (item.rank > 10) {
        break;
      }
      let list = [{
        "Ranking": item.rank,
        "CKC Dog Number (Dog)": item.dog_number,
        "Name (Dog)": item.dog_name,
        "Dog Name with Title (Dog)": item.dog_title,
        "Breed (Dog)": item.new_breed,
        "Breed Size (Dog)": item.dog_breed_size,
        "Breed Variety (Dog)": item.dog_breed_variety,
        "CCN Breed (Dog)": item.ccn_breed,
        "Group": getGroupName(item.new_breed),
        "Number of qualifying scores": item.no_of_trials,
        "Average score": item.average_score,
        "Total points": item.total_points,
        "Full Name (Contact)": item.contact_data.name,
        "First Name (Contact)": item.contact_data.first_name,
        "Last Name (Contact)": item.contact_data.last_name,
        "CKC # (Contact)": item.contact_data.contact_number,
        "Email (Contact)": item.contact_data.email,
        "Address 1 (Contact)": item.contact_data.address,
        "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
        "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
        "Address 1: City (Contact)": item.contact_data.city,
        "Address 1: State/Province (Contact)": item.contact_data.area,
        "Address 1: Country (Contact)": item.contact_data.region,
        "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
      }];
      rankingForCSVAgilityHerding = rankingForCSVAgilityHerding.concat(list);
    }
 
    // console.log(rankingForCSVAgilityHerding);


    for (var i = 0; i < allBreads.sort().length; i++) {
      let tempList = calculateRank(overallRankingList.filter(obj => {
        return obj.new_breed === allBreads[i];
      }));

      rankingForCSVAgilityHerdingBreedWise = rankingForCSVAgilityHerdingBreedWise.concat([{
        "Breed": allBreads[i],
        "Ranking": "",
        "CKC Dog Number (Dog)": "",
        "Name (Dog)": "",
        "Dog Name with Title (Dog)": "",
        "Breed (Dog)": "",
        "Breed Size (Dog)": "",
        "Breed Variety (Dog)": "",
        "CCN Breed (Dog)": "",
        "Group": "",
        "Number of qualifying scores": "",
        "Average score": "",
        "Total points": "",
        "Full Name (Contact)": "",
        "First Name (Contact)": "",
        "Last Name (Contact)": "",
        "CKC # (Contact)": "",
        "Email (Contact)": "",
        "Address 1 (Contact)": "",
        "Address 1: Street 1 (Contact)": "",
        "Address 1: Street 2 (Contact)": "",
        "Address 1: City (Contact)": "",
        "Address 1: State/Province (Contact)": "",
        "Address 1: Country (Contact)": "",
        "Address 1: Zip/Postal Code (Contact)": "",
      }]);

      if (eventCat === "Lure Coursing" || eventCat === "Herding") {
        no_of_export_breed_wise = 10;

      } else {
        no_of_export_breed_wise = 5;
      }
      for (var j = 0; j < tempList.length; j++) {
        let item = tempList[j];
        if (item.new_rank > no_of_export_breed_wise) {
          break;
        }
        let breedRankingList = [{
          "Breed": "",
          "Ranking": item.new_rank,
          "CKC Dog Number (Dog)": item.dog_number,
          "Name (Dog)": item.dog_name,
          "Dog Name with Title (Dog)": item.dog_title,
          "Breed (Dog)": item.new_breed,
          "Breed Size (Dog)": item.dog_breed_size,
          "Breed Variety (Dog)": item.dog_breed_variety,
          "CCN Breed (Dog)": item.ccn_breed,
          "Group": getGroupName(item.new_breed),
          "Number of qualifying scores": item.no_of_trials,
          "Average score": item.average_score,
          "Total points": item.total_points,
          "Full Name (Contact)": item.contact_data.name,
          "First Name (Contact)": item.contact_data.first_name,
          "Last Name (Contact)": item.contact_data.last_name,
          "CKC # (Contact)": item.contact_data.contact_number,
          "Email (Contact)": item.contact_data.email,
          "Address 1 (Contact)": item.contact_data.address,
          "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
          "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
          "Address 1: City (Contact)": item.contact_data.city,
          "Address 1: State/Province (Contact)": item.contact_data.area,
          "Address 1: Country (Contact)": item.contact_data.region,
          "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
        }];
        rankingForCSVAgilityHerdingBreedWise = rankingForCSVAgilityHerdingBreedWise.concat(breedRankingList);

      }
    }
  }

  if (allGroups.length > 0) {
    for (var i = 1; i < 8; i++) {

      let breedList = allGroups.filter(obj => {
        return obj.GroupName === "Group " + i;
      });

      let tempList = calculateRank(overallRankingList.filter((el) => {
        return breedList.some((f) => {
          return f.CKCBreedName === el.new_breed;
        });
      }));

      //  console.log("Group "+i,tempList);

      rankingForCSVAgilityHerdingGroupWise = rankingForCSVAgilityHerdingGroupWise.concat([{
        "Group": 'Group ' + i,
        "Ranking": "",
        "CKC Dog Number (Dog)": "",
        "Name (Dog)": "",
        "Dog Name with Title (Dog)": "",
        "Breed (Dog)": "",
        "Breed Size (Dog)": "",
        "Breed Variety (Dog)": "",
        "CCN Breed (Dog)": "",
        "Number of qualifying scores": "",
        "Average score": "",
        "Total points": "",
        "Full Name (Contact)": "",
        "First Name (Contact)": "",
        "Last Name (Contact)": "",
        "CKC # (Contact)": "",
        "Email (Contact)": "",
        "Address 1 (Contact)": "",
        "Address 1: Street 1 (Contact)": "",
        "Address 1: Street 2 (Contact)": "",
        "Address 1: City (Contact)": "",
        "Address 1: State/Province (Contact)": "",
        "Address 1: Country (Contact)": "",
        "Address 1: Zip/Postal Code (Contact)": "",
      }]);

      for (var j = 0; j < tempList.length; j++) {


        let item = tempList[j];
        if (item.new_rank > 10) {
          break;
        }

        let groupRankingList = [{
          "Group": "",
          "Ranking": item.new_rank,
          "CKC Dog Number (Dog)": item.dog_number,
          "Name (Dog)": item.dog_name,
          "Dog Name with Title (Dog)": item.dog_title,
          "Breed (Dog)": item.new_breed,
          "Breed Size (Dog)": item.dog_breed_size,
          "Breed Variety (Dog)": item.dog_breed_variety,
          "CCN Breed (Dog)": item.ccn_breed,
          "Number of qualifying scores": item.no_of_trials,
          "Average score": item.average_score,
          "Total points": item.total_points,
          "Full Name (Contact)": item.contact_data.name,
          "First Name (Contact)": item.contact_data.first_name,
          "Last Name (Contact)": item.contact_data.last_name,
          "CKC # (Contact)": item.contact_data.contact_number,
          "Email (Contact)": item.contact_data.email,
          "Address 1 (Contact)": item.contact_data.address,
          "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
          "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
          "Address 1: City (Contact)": item.contact_data.city,
          "Address 1: State/Province (Contact)": item.contact_data.area,
          "Address 1: Country (Contact)": item.contact_data.region,
          "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
        }];
        rankingForCSVAgilityHerdingGroupWise = rankingForCSVAgilityHerdingGroupWise.concat(groupRankingList);
      }

    }
  }

  if (allClasses.length > 0) {

    for (var i = 0; i < allClasses.sort().length; i++) {
      let tempList = calculateRank(overallRankingList.filter(obj => {
        return obj.class === allClasses[i];
      }));

      if (tempList.length > 0) {
        rankingForCSV = rankingForCSV.concat([{
          "Class": eventCat === "Beagle" ? beagleClasses[i] : allClasses[i],
          "Ranking": "",
          "CKC Dog Number (Dog)": "",
          "Name (Dog)": "",
          "Dog Name with Title (Dog)": "",
          "Breed (Dog)": "",
          "Group": "",
          "Total points": "",
          "Full Name (Contact)": "",
          "First Name (Contact)": "",
          "Last Name (Contact)": "",
          "CKC # (Contact)": "",
          "Email (Contact)": "",
          "Address 1 (Contact)": "",
          "Address 1: Street 1 (Contact)": "",
          "Address 1: Street 2 (Contact)": "",
          "Address 1: City (Contact)": "",
          "Address 1: State/Province (Contact)": "",
          "Address 1: Country (Contact)": "",
          "Address 1: Zip/Postal Code (Contact)": "",
        }]);

        for (var j = 0; j < tempList.length; j++) {
          let item = tempList[j];
          if (item.new_rank > 10) {
            break;
          }
          let classRankingList = [{
            "Class": "",
            "Ranking": item.new_rank,
            "CKC Dog Number (Dog)": item.dog_number,
            "Name (Dog)": item.dog_name,
            "Dog Name with Title (Dog)": item.dog_title,
            "Breed (Dog)": item.new_breed,
            "Group": getGroupName(item.new_breed),
            "Total points": item.total_points,
            "Full Name (Contact)": item.contact_data.name,
            "First Name (Contact)": item.contact_data.first_name,
            "Last Name (Contact)": item.contact_data.last_name,
            "CKC # (Contact)": item.contact_data.contact_number,
            "Email (Contact)": item.contact_data.email,
            "Address 1 (Contact)": item.contact_data.address,
            "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
            "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
            "Address 1: City (Contact)": item.contact_data.city,
            "Address 1: State/Province (Contact)": item.contact_data.area,
            "Address 1: Country (Contact)": item.contact_data.region,
            "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
          }];
          rankingForCSV = rankingForCSV.concat(classRankingList);

        }
      }

    }
  }
  return (

    <div className='listing-page-body'>
      <div className='topHeader'>
        <div className='leftSide'>
          <p className='ckc-name'>Canadian Kennel Club Top Dogs</p>
          <img src={logo} alt="Logo" className='logo' />
          <p className='divider-line'></p>
          <div className='event-details'>
            <p className='event-heading'>{eventCat === "Pointing - Field Trial"?"Pointing Field Trial":eventCat==="Beagle"?"Beagle Field Trial":eventCat}</p>
            <p className='event-date'>From {year}-01-01 to {year}-12-31</p>
            <p className='posting-date'>Last Update: {postingDates.length>0?postingDates[0].lastUpdatedDate:""}</p>
            <p className='posting-date'>Results Current To:  {postingDates.length>0?postingDates[0].resultsCurrentToDate:""}</p>


          </div>
        </div>
      </div>

      <div
        style={{ paddingLeft: '120px', paddingRight: '120px', paddingTop: '20px' }}

      >
        <p className='top-dog-stand-head'>Top Dog Standings</p>

        <div>
          <Row>
            <InputGroup sm={12}>
              {/* <Input
                id="eventYear"
                name="eventYear"
                type="select"
                onChange={(e) => setYear(e.target.value)}
                required
              >
                {eventCat!=="Spaniel Field Trial"?<option >2022</option>:""}
                <option selected>2023</option>


              </Input> */}
              <Input id="eventYear" name="eventYear" type="select" onChange={(e) => setYear(e.target.value)} required>
                {[...Array(3)].map((_, index) => {
                  const year = new Date().getFullYear() - index;
                  return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                })}
              </Input>
                {eventCat === "Beagle" || eventCat === "Pointing - Field Trial" || eventCat === "Spaniel Field Trial"|| eventCat === "Retriever Field Trial"? eventCat === "Beagle" ? (
                  <Input
                    sm={3}
                    id='classOrStake'
                    type='select'
                    name='classOrStake'
                    className='search-field1'
                    onChange={handleClassChange}
                    placeholder='Dog Class' >
                    <option selected>All</option>
                    <option>Males 15"</option>
                    <option>Females 15"</option>
                    <option>Males 13"</option>
                    <option>Females 13"</option>
                  </Input>
                ) 
              : eventCat === "Retriever Field Trial" ? (
                  <Input
                    sm={4}
                    id='classOrStake'
                    type='select'
                    name='classOrStake'
                    className='search-field1'
                    onChange={handleClassChange}
                    placeholder='Dog Class' >
                    <option selected>All</option>
                    <option>Combined</option>
                    <option>Open All-Age</option>
                    <option>Amateur All-Age</option>
                    <option>Qualifying</option>
                    <option>Junior</option>

                  </Input>
                  )
              : eventCat === "Pointing - Field Trial" ? (
                <Input
                  sm={4}
                  id='classOrStake'
                  type='select'
                  name='classOrStake'
                  className='search-field1'
                  onChange={handleClassChange}
                  placeholder='Dog Class' >
                  <option selected>All</option>
                  <option>Open Shooting Dog</option>
                  <option>Amateur Shooting Dog</option>
                  <option>Open Derby</option>
                  <option>Open Puppy</option>
                </Input>
                ) 
                : (

                  <Input
                    sm={4}
                    id='classOrStake'
                    type='select'
                    name='classOrStake'
                    className='search-field1'
                    onChange={handleClassChange}
                    placeholder='Dog Class' >
                    <option selected>All</option>
                    <option>Open All-Age</option>
                    <option>Amateur All-Age</option>
                    <option>Puppy</option>
                    <option>Limited</option>
                  </Input>
                )

                :
                (
                  <Input
                    sm={4}
                    id='breeds'
                    type='select'
                    name='breeds'
                    className='search-field1'
                    onChange={handleBreedChange}
                    placeholder='Dog Breed' >
                    <option>Select</option>
                    <option selected>All Breeds</option>
                    {allBreads.sort().map(function (item, i) {
                      return <option>{item}</option>
                    })}
                  </Input>

                )}

              <Input
                sm={4}
                id='groups'
                type='select'
                name='groups'
                className='search-field1'
                onChange={handleGroupChange}
                placeholder='Dog Group' >
                <option>Select Group</option>
                <option value={1}>Group 1</option>
                <option value={2}>Group 2</option>
                <option value={3}>Group 3</option>
                <option value={4}>Group 4</option>
                <option value={5}>Group 5</option>
                <option value={6}>Group 6</option>
                <option value={7}>Group 7</option>

              </Input>
              {user?
              (eventCat === "Agility" || eventCat === "Herding" || eventCat === "Lure Coursing") ?
                <Dropdown sm={4}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <CSVLink style={{ marginTop: '10px', color: red, fontSize: '17px' }} data={rankingForCSVAgilityHerding} filename={eventCat + "_standings_" + year + "_all_breed"}>All Breeds</CSVLink>
                    </Dropdown.Item >
                    <Dropdown.Item href="#/action-2">
                      <CSVLink style={{ marginTop: '10px', color: red, fontSize: '17px' }} data={rankingForCSVAgilityHerdingBreedWise} filename={eventCat + "_standings_" + year + "_breed_wise"}>Breed Wise</CSVLink>
                    </Dropdown.Item >
                    <Dropdown.Item href="#/action-3">
                      <CSVLink style={{ marginTop: '10px', color: red, fontSize: '17px' }} data={rankingForCSVAgilityHerdingGroupWise} filename={eventCat + "_standings_" + year + "_group_wise"}>Group Wise</CSVLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                : <Button className='search-button' sm={4}>
                  <CSVLink style={{ marginTop: '10px', color: red, fontSize: '17px' }} data={rankingForCSV} filename={eventCat + "_standings_" + year + "_class_wise"}>Export</CSVLink>
                </Button>
              :("")}

              {/* <Button className='search-button' sm={4}><CSVLink style={{ marginTop: '10px', color: red, fontSize: '17px' }} data={(eventCat === "Agility"||eventCat==="Herding") ?rankingForCSVAgilityHerding:rankingForCSV} filename={"standings_" + eventCat + year}>Export</CSVLink>
                </Button> */}
            </InputGroup>
          </Row>
        </div>
        <div className='divider-line1' />
          {loadingGetOverallResult ? (
            <div className="loader" >
                <ContentLoader 
                  speed={1}
                  width={'100%'}
                  height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                  viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <rect
                      key={index}
                      x="0"
                      y={(70 + 4) * index}
                      rx="5"
                      ry="5"
                      width="100%"
                      height="70"
                    />
                  ))}
                </ContentLoader>
            </div>
            ) : (
            <DataTable
              columns={eventCat === "Beagle"||eventCat === "Pointing - Field Trial" ||eventCat === "Retriever Field Trial"||eventCat === "Spaniel Field Trial"? (columnsBeagle) : eventCat ==="Lure Coursing"? (columnsLureCoursing):(columnsAgility)}
              data={calculateRank(filteredRankingList)}
              filter
              theme=""
              className='publicRankingTable'
              // progressPending={newLoading ? newLoading : loadingGetOverallResult}
              highlightOnHover
              defaultSortAsc={false}
              defaultSortFieldId={"totalPoints"}
            />
          )}
      </div>
    </div>
  );
};
