import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
//apply base url for axios
const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

const axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL,
});

axios.interceptors.request.use(function (config) {
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) =>{
    console.log("errror",error.response.status);
    if(error.response.status===401){
      window.location.replace('/error-page');
      const {logout } = useAuth();
      logout();
    }
return    Promise.reject(error);

  }
  
);

// const AxiosInterceptor = ({ children }) => {
//   const navigate = useNavigate();
//   const { logout } = useAuth();
//   useEffect(() => {
//     const resInterceptor = (response) => {
//       return response;
//     };

//     const errInterceptor = (error) => {
//       console.log("erererer",error);
//       if (error.response.status === 401) {
//         console.log("error interceptor", error.response.status);
//         logout();
//          // navigate('/', { replace: true });
//       }

//       return Promise.reject(error);
//     };

//     const interceptor = axiosApi.interceptors.response.use(
//       resInterceptor,
//       errInterceptor
//     );

//     return () => axiosApi.interceptors.response.eject(interceptor);
//   }, []);

//   return children;
// };
// export { AxiosInterceptor };
export async function get(url, token, config) {
  return await axiosApi
    .get(url, {
      headers: { Authorization: "Bearer " + token },
      ...config,
    })
    .then((response) => response.data);
}
export async function postLogin(url, data, config) {
  return await axiosApi
    .post(url, data, {
      ...config,
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response);
}

export async function post(url, data, token, config) {
  return await axiosApi
    .post(url, data, {
      ...config,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data);
}

export async function postEvent(url, data, token, config) {
  return await axiosApi
    .post(url, data, {
      ...config,
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response.data);
}

export async function put(url, data, token, config) {
  return await axiosApi
    .put(url, data, {
      ...config,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data);
}

export async function putWithoutToken(url, data, config) {
  return await axiosApi
    .put(url, data, {
      ...config,
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.data);
}

export async function delItem(url, data, token, config) {
  return await axiosApi
    .delete(url, {
      ...config,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data,
    })
    .then((response) => response.data);
}
