import { Button, Form, FormGroup, Label, Input, Row, Badge } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Card, CardHeader, Alert, CardBody } from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getOverallResult, calculateEventResult,calculateOverallResult } from '../store/events/actions';
import "../styles.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import LaunchIcon from '@mui/icons-material/Launch';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ContentLoader from "react-content-loader";

function OverallResults() {

  let allClubs = [];
  const navigate = useNavigate();
  const { overallRankingList,error, loadingGetOverallResult, loadingOverallResult,calculateOverallResultRespone } = useSelector((state) => state.EventsReducer);
  const location = useLocation();
  const { user, logout } = useAuth();
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [eventCat,setEventCat]=useState("Agility");

  allClubs = [...new Set(overallRankingList.map((item) => item.club))];
  if(!loadingGetOverallResult&&error.message==="Unauthorised Access"){
    logout();
  }
  const REACT_APP_FRONT_URL = process.env.REACT_APP_FRONT_URL;

  const formatDogName = (row) => {
    let string = row.dog_title;
    let arrayOfString = string.includes(" ") ? string.split(" ") : [string];
    let characterLength = 0;
    let lineIndex = 0;
    let line = [];
    let totalValues = arrayOfString.length;
    let formattedText = '';
    if (totalValues > 1) {
      arrayOfString.forEach((value, key) => {
          let valCharLength = value.length + 1; // add 1 for the space after each word
          characterLength += valCharLength;
          
          if (characterLength <= 31) {
              line[lineIndex] = line[lineIndex] || [];
              line[lineIndex].push(value);
          } else {
              lineIndex++;
              if (key === totalValues - 1) { // if it's the last element in the array
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(line[lineIndex - 1].pop()); // Get element from last line
                  line[lineIndex].push(value);
              } else {
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(value);
              }
              characterLength = valCharLength; // reset character length for the new line
          }
      });

      // Join lines with <br> tag
      formattedText = line.map(line => line.join(' ')).join("\n");
    }
    else{
      formattedText = arrayOfString;
    }
    //console.log(formattedText);
    return formattedText;
  };

  const columns = useMemo(() => [
    {
      name: 'Rank',
      selector: row => row.rank,
      sortable: true,
      wrap: true,
      center: true,
    },
    {
      name: 'Dog Title',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        </Row>
      ),
    },
    {
      name: 'Dog Number',
      selector: row => row.dog_number,
      sortable: true,
      wrap: true,
      center:true,
      grow: 2
    },
    {
      name: 'Breed',
      selector: row => row.new_breed,
      sortable: true,
      wrap: true,
      center:true,

      grow: 2
    },
   
    {
      id: 'totalPoints',
      name: 'Total Points',
      selector: row => row.total_points,
      sortable: true,
      center:true,
      wrap: true,
    },
    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      sortable: true,
      wrap: true,
      center:true,
      grow: 2
    },
    {
      name: 'Average Score',
      selector: row => row.average_score,
      sortable: true,
      center:true,
      wrap: true,

    }
  ], []);

  const otherColumns = useMemo(() => [
    {
      name: 'Rank',
      selector: row => row.rank,
      sortable: true,
      wrap: true,
      center: true,
    },
    {
      name: 'Dog Title',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        </Row>
      ),
    },
    {
      name: 'Dog Number',
      selector: row => row.dog_number,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Breed',
      selector: row => row.new_breed,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      id: 'totalPoints',
      name: 'Total Points',
      selector: row => row.total_points,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      sortable: true,
      wrap: true,
      center:true,
      grow: 2
    }
  ], []);


  const breedWithSizeVariety=(breed,size,variety)=>{
    let new_breed;

    if(breed==="Unrecognized breed"){
    new_breed="Pumi";
    }else if(breed==="Belgian Shepherd Dog"||breed==="Chinese Crested Dog"){
      new_breed= breed;
    }else{
      new_breed= breed+(size?" ("+size+")":"")+(variety?" ("+variety+")":"");

    }
    return new_breed;
  }


  let dispatch = useDispatch();

  useEffect(() => {
    const startEpoch= new Date("Jan 01,"+year+" 00:00:00 GMT").getTime();
    const endEpoch= new Date("Dec 31,"+year+" 00:00:00 GMT").getTime();
    dispatch(getOverallResult(eventCat+"&start_year="+startEpoch+"&end_year="+endEpoch,user.token))

  }, [calculateOverallResultRespone]);

  const submitData = async () => {
    var startEpoch;
    var endEpoch;
    if(eventCat==="Spaniel Field Trial"){
      switch(year){
        case 2021:
            startEpoch= new Date("Oct 19,2020 00:00:00 GMT").getTime();
            endEpoch= new Date("Oct 18,2021 00:00:00 GMT").getTime();       
            break;
        case 2022:
            startEpoch= new Date("Oct 19,2021 00:00:00 GMT").getTime();
            endEpoch= new Date("Oct 01,2022 00:00:00 GMT").getTime();       
            break;
        case 2023:
            startEpoch= new Date("Oct 2,2022 00:00:00 GMT").getTime();
            endEpoch= new Date("Dec 31,2023 00:00:00 GMT").getTime();       
            break;
        default:
            startEpoch= new Date("Jan 01,"+year+" 00:00:00 GMT").getTime();
            endEpoch= new Date("Dec 31,"+year+" 00:00:00 GMT").getTime();         
            break;
      }
    }else{
      startEpoch= new Date("Jan 01,"+year+" 00:00:00 GMT").getTime();
      endEpoch= new Date("Dec 31,"+year+" 00:00:00 GMT").getTime();
    }

    const searchData = {
      "event_category":eventCat,
      "start_epoch":startEpoch,
      "end_epoch":endEpoch
    };

    setYear(year);
    setEventCat(eventCat);
    dispatch(calculateOverallResult(searchData, navigate, user.token));
  };
  const searchOverallResult = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setYear(data.get("eventYear"));
    setEventCat(data.get("eventCat"));
    const startEpoch= new Date("Jan 01,"+data.get("eventYear")+" 00:00:00 GMT").getTime();
    const endEpoch= new Date("Dec 31,"+data.get("eventYear")+" 00:00:00 GMT").getTime();
    dispatch(getOverallResult(data.get("eventCat")+"&start_year="+startEpoch+"&end_year="+endEpoch,user.token))
  };


  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <div className='page-heading'>
        <Row>
          <Col sm="3">
            Overall Results
          </Col>
          <Col sm="2"></Col>
          <Col sm="6" className='class-col'>
            {overallRankingList && overallRankingList.length === 0 ? (
              <Button title='Result' color='primary' outline size='sm' onClick={() => submitData()}> {loadingOverallResult ? ('Calculating...') : ('Calculate Result')}</Button>
            ) : (
            <Row>
              <Button title='Result' color='primary' outline size='sm' onClick={() => submitData()}> {loadingOverallResult ? ('Calculating...') : ('Calculate Again')}</Button>
              <Button color='primary' outline  target="_blank" size='sm' href={REACT_APP_FRONT_URL+"overall_ranking"}>Top Dogs <LaunchIcon/></Button>
            </Row>
            )}
          </Col>
        </Row>
      </div>
      <ToastContainer />
      <div className='page-body'>
        {loadingOverallResult ? (
          <Alert color="primary">
            Please wait while we are calculating Points. It can take few minutes...
          </Alert>
        ) :
        ('')}
        <Card className="my-2" style={{ padding: 0 }}>
          <CardHeader>
            <Form onSubmit={searchOverallResult}>
              <FormGroup row>
                <Label for="eventCat" sm={2}> Event Category</Label>
                <Col sm={3}>
                  <Input id="eventCat" name="eventCat" type="select" required>
                    <option>Agility</option>
                    <option>Beagle</option>
                    <option>Lure Coursing</option>
                    <option>Herding</option>
                    <option>Pointing - Field Trial</option>
                    <option>Retriever Field Trial</option>
                    <option>Spaniel Field Trial</option>
                    <option>Sprinter</option>
                  </Input>
                </Col>
                <Label for="eventYear" sm={2}> Choose Year</Label>
                {/* <Col sm={3}>
                  <Input id="eventYear" name="eventYear" type="select" required>
                      <option >2022</option>
                      <option selected>2023</option>
                  </Input>
                </Col> */}
                <Col sm={2} className='marginRight'>
                  <Input id="eventYear" name="eventYear" type="select" onChange={(e) => setYear(e.target.value)} required>
                    {[...Array(3)].map((_, index) => {
                      const year = new Date().getFullYear() - index;
                      return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                    })}
                  </Input>
                </Col>
                <Col sm={2}>
                 <Button color='primary' outline>Search</Button>
                </Col>
              </FormGroup>
            </Form>
          </CardHeader>
          <CardBody className='event-card-body'>
            {loadingGetOverallResult ? (
              <div className="loader" >
                 <ContentLoader 
                    speed={1}
                    width={'100%'}
                    height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                    viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    {Array.from({ length: 10 }, (_, index) => (
                      <rect
                        key={index}
                        x="0"
                        y={(70 + 4) * index}
                        rx="5"
                        ry="5"
                        width="100%"
                        height="70"
                      />
                    ))}
                  </ContentLoader>
              </div>
            ) : (
              <DataTable
                columns={eventCat==="Agility"?columns:otherColumns}
                data={overallRankingList}
                theme=""
                pagination
                paginationRowsPerPageOptions={[50,100,200,300]}
                paginationPerPage={50}
                highlightOnHover
                defaultSortAsc={false}
                defaultSortFieldId={"totalPoints"}
                fixedHeader
                sortIcon={<FilterListIcon />}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default OverallResults;
