export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";



export const ACTIVATE_ACCOUNT="ACTIVATE_ACCOUNT";
export const ACTIVATE_ACCOUNT_SUCCESS="ACTIVATE_ACCOUNT_SUCCESS";
export const ACTIVATE_ACCOUNT_FAILURE="ACTIVATE_ACCOUNT_FAILURE";


export const RESET_USER_PASSWORD="RESET_USER_PASSWORD";
export const RESET_USER_PASSWORD_SUCCESS="RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_FAILURE="RESET_USER_PASSWORD_FAILURE";

export const DEL_USER="DEL_USER";
export const DEL_USER_SUCCESS="DEL_USER_SUCCESS";
export const DEL_USER_FAILURE="DEL_USER_FAILURE";

