import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDogs, delDog } from '../store/dogs/actions';
import "../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ContentLoader from "react-content-loader";

function Dogs() {

  const { user, logout } = useAuth();
  const [modal, setModal] = useState(false);
  const [delete_dog_id, setId] = useState();

  const toggle = (delete_dog_id) => {
    setModal(!modal);
    setId(delete_dog_id);
  }

  const navigate = useNavigate();
  const { dogList, loading, error, delDogResponse, loadingDelDog } = useSelector((state) => state.DogsReducer);

  //if(!loading&&error.message==="Unauthorised Access"){
   // logout();
  //}
  const columns = useMemo(() => [
    {
      name: 'CKC Dodg Number',
      selector: row => row.dog_number,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Name(Dog)',
      selector: row => row.name,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Dog Name with Title(Dog)',
      selector: row => row.title,
      sortable: true,
      wrap: true,
      grow: 3
    },
    {
      name: 'Gender(Dog)',
      selector: row => row.gender,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Breed(Dog)',
      selector: row => row.breed,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Breed Size(Dog)',
      selector: row => row.breed_size,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Breed Variety',
      selector: row => row.breed_variety,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Manage',
      ignoreRowClick: true,
      grow: 1,
      width: "200px",
      cell: row => (<Row>
        <Col>
          <Button title='Edit' outline className='manage-event' onClick={() => editDogData(row.index, row)}><EditIcon /></Button>
          <Button title='Delete' outline className='manage-event' onClick={() => toggle(row.dog_id)}><DeleteForeverIcon /></Button>
        </Col>
      </Row>

      ),

    }
  ], []);






  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDogs(user.token));

  }, []);

  const editDogData = (index, row) => {
    navigate('/dashboard/edit-dog', { state: { index: index, data: row } });
  }

  const deleteDog = (delete_dog_id) => {
    const delData = {
      "dog_id": delete_dog_id
    };
    console.log(delData);
    dispatch(delDog(delData, navigate, user.token));
  }
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <p className='page-heading'>All Dogs <nbsp />

      </p>


      <ToastContainer />

      <div className='page-body'>


        <Card
          className="my-2"
          style={{ padding: 0 }}

        >
          <CardBody className='hotel-locations-card-body'>

            {loading ? (
              <div className="loader" >
                <ContentLoader 
                  speed={1}
                  width={'100%'}
                  height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                  viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <rect
                      key={index}
                      x="0"
                      y={(70 + 4) * index}
                      rx="5"
                      ry="5"
                      width="100%"
                      height="70"
                    />
                  ))}
                </ContentLoader>
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={dogList}
                pagination
                paginationRowsPerPageOptions={[50, 100]}
                paginationPerPage={50}
                highlightOnHover
                fixedHeader
                sortIcon={<FilterListIcon />}
              />
            )}
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Entry</ModalHeader>
        <ModalBody>
          Are you sure you want to Delete this record?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteDog(delete_dog_id)}>
            {loadingDelDog ? ('Deleting...') : ('Yes, Delete')}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

export default Dogs;
