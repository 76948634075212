import {
  GET_EVENT_DISPLAY_RESULT_FILES,
  GET_EVENT_DISPLAY_RESULT_FILES_FAIL,
  GET_EVENT_DISPLAY_RESULT_FILES_SUCCESS,
  ADD_DISPLAY_EVENT_FILE,
  ADD_DISPLAY_EVENT_FILE_FAILURE,
  ADD_DISPLAY_EVENT_FILE_SUCCESS,
  DEL_DISPLAY_EVENT_FILE,
  DEL_DISPLAY_EVENT_FILE_SUCCESS,
  DEL_DISPLAY_EVENT_FILE_FAILURE
} from "./actionTypes";

const initialState = {
  eventList: [],
  attendeeList: [],
  dogPoints: [],

  rankingList: [],
  overallRankingList: [],
  loading: false,
  loadingGetEventAttendee: false,
  loadingGetEventResult: false,
  loadingGetOverallResult: false,
  loadingGetClubWiseRanking: false,
  clubWiseResponse:[],
  loadingGetDogResult: false,

  error: {
    message: "",
    code: "",
  },
  addEventResponse: {},
  loadingAddEvent: false,
  calculateResultResponse: {},
  loadingCalculateResult: false,

  fileEventList: [],
  loadingGetFileEventList: false,

  publicRankingList: [],
  loadingGetEventPublicResult: false,

  delFileResponse: {},
  loadingDelFile: false,

  calculateOverallResultRespone: {},
  loadingOverallResult: false,

  fileResultRes: [],
  loadingGetFileResults: false
};

const EventResultsDisplayReducer = (state = initialState, action) => {


  switch (action.type) {

    case ADD_DISPLAY_EVENT_FILE:
      state = {
        ...state,
        loadingAddEvent: true
      };
      break;
    case ADD_DISPLAY_EVENT_FILE_SUCCESS:
      state = {
        ...state,
        addEventResponse: action.payload,
        loadingAddEvent: false
      };

      break;
    case ADD_DISPLAY_EVENT_FILE_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingAddLocation: false,
      };
      break;

    case GET_EVENT_DISPLAY_RESULT_FILES:
      state = {
        ...state,
        loading: true
      };
      break;
    case GET_EVENT_DISPLAY_RESULT_FILES_SUCCESS:
      console.log("action data", action);
      state = {
        ...state,
        eventList: action.payload.eventFileList,
        loading: false
      };
      break;
    case GET_EVENT_DISPLAY_RESULT_FILES_FAIL:
      state = {
        ...state,
        error: {
        message:  action.payload.data.message,
        },
        loading: false,
      };
      break;
    

    case DEL_DISPLAY_EVENT_FILE:
      state = {
        ...state,
        loadingDelFile: true
      };
      break;
    case DEL_DISPLAY_EVENT_FILE_SUCCESS:
      state = {
        ...state,
        delFileResponse: action.payload,
        loadingDelFile: false
      };

      break;
    case DEL_DISPLAY_EVENT_FILE_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingDelFile: false,
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default EventResultsDisplayReducer;