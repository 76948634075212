import { useState } from 'react';
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../hooks/useAuth";
import {
  UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

const Topbar = ({ toggleSidebar }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Navbar
      color="light"
      light
      className="navbar shadow-sm p-3 mb-3 bg-white rounded"
      expand="md"
    >
      <Button  onClick={toggleSidebar}>
      <FontAwesomeIcon icon={faAlignLeft} />
      </Button>

   
      
    </Navbar>
  );
};

export default Topbar;
