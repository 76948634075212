import DataTable, { createTheme } from 'react-data-table-component';
import React, { useMemo } from 'react';
import { Row, Col, Input, InputGroup, Button } from 'reactstrap';
import logo from '../assets/ckc-logo.png';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClubWiseRanking } from '../store/events/actions';
import { Circles } from "react-loader-spinner";
import { CSVLink, CSVDownload } from "react-csv";
import { red } from '@mui/material/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import { getDay } from 'rsuite/esm/utils/dateUtils';
import ContentLoader from "react-content-loader";

export const ClubWiseRanking = () => {

  const { clubWiseResponse, loadingGetClubWiseRanking, } = useSelector((state) => state.EventsReducer);
  const [year, setYear] = useState("2022");
  const [club, setClub] = useState("Alberta Kennel Club");
  let allClubs = [];
  const [filteredRankingList, setFilteredRankingList] = useState([]);
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  let dispatch = useDispatch();

  useEffect(() => {
    const startEpoch = new Date("Jan 01," + year + " 00:00:00 GMT").getTime();
    const endEpoch = new Date("Dec 31," + year + " 00:00:00 GMT").getTime();
    dispatch(getClubWiseRanking("?start_year=" + startEpoch + "&end_year=" + endEpoch));


  }, [year]);

  useEffect(() => {
    setFilteredRankingList(clubWiseResponse.filter(obj => {
      return obj.club === club;
    }));
  }, [club, clubWiseResponse]);


  allClubs = [...new Set(clubWiseResponse.map((item) => item.club))];
  function getDay(date) {
    const d = new Date(date);
    let day = d.getDay();
    return weekday[day];
  }

  const columns = useMemo(() => [

    {
      name: 'Day',
      selector: row => getDay(row.start_date),
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Position',
      sortable: true,
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name'>Best In Show</p>

      </Row>

      ),
    },
    {
      name: 'Judge',
      selector: row => row.judge,
      sortable: true,
      wrap: true,
      grow: 2
    },

    {
      name: 'Points',
      selector: row => row.total_points,
      sortable: true,
      center: true,
      wrap: true,
    },


  ], []);


  return (

    <div className='listing-page-body'>
      <div className='topHeader'>
        <Row sm={12}>
          <Col sm={10} className='align-self-center'>
            <p className='ckc-name'>Club Events</p>


            <Input
              id="clubList"
              name="clubList"
              className='clubList'
              type="select"
              onChange={(e) => setClub(e.target.value)}
              required
            >
              {allClubs.sort().map(function (item, i) {
                return <option>{item}</option>
              })}

            </Input>

          </Col>
          <Col sm={2} className='align-self-center'>

            <img src={logo} alt="Logo" className='logo' />
          </Col>
        </Row>

      </div>

      <div
        style={{ paddingLeft: '120px', paddingRight: '120px', paddingTop: '20px' }}

      >
        <p className='top-dog-stand-head'>{club}</p>

        <div className='divider-line1' />
          {loadingGetClubWiseRanking ? (
            <div className="loader" >
                <ContentLoader 
                  speed={1}
                  width={'100%'}
                  height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                  viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <rect
                      key={index}
                      x="0"
                      y={(70 + 4) * index}
                      rx="5"
                      ry="5"
                      width="100%"
                      height="70"
                    />
                  ))}
                </ContentLoader>
            </div>
            ) : (
            <DataTable
              columns={columns}
              data={filteredRankingList ? filteredRankingList : clubWiseResponse}
              filter
              theme=""
              className='publicRankingTable'
              // progressPending={loadingGetClubWiseRanking}
              highlightOnHover
            />
          )}
      </div>

    </div>

  );

};
