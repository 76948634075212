import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_EVENT_ATTENDEE,
  GET_EVENT_ATTENDEE_SUCCESS,
  GET_EVENT_ATTENDEE_FAIL,
  GET_EVENT_RESULT,
  GET_EVENT_RESULT_SUCCESS,
  GET_EVENT_RESULT_FAIL,
  CALCULATE_EVENT_RESULT,
  CALCULATE_EVENT_RESULT_SUCCESS,
  CALCULATE_EVENT_RESULT_FAILURE,
  GET_EVENT_LIST,
  GET_EVENT_LIST_FAIL,
  GET_EVENT_LIST_SUCCESS,
  GET_EVENT_PUBLIC_RESULT,
  GET_EVENT_PUBLIC_RESULT_SUCCESS,
  GET_EVENT_PUBLIC_RESULT_FAIL,
  DEL_EVENT_FILE,
  DEL_EVENT_FILE_SUCCESS,
  DEL_EVENT_FILE_FAILURE,
  CALCULATE_OVERALL_RESULT,
  CALCULATE_OVERALL_RESULT_FAILURE,
  CALCULATE_OVERALL_RESULT_SUCCESS,
  GET_OVERALL_RESULT,
  GET_OVERALL_RESULT_SUCCESS,
  GET_OVERALL_RESULT_FAIL,
  GET_CLUB_WISE_RANKING,
  GET_CLUB_WISE_RANKING_SUCCESS,
  GET_CLUB_WISE_RANKING_FAIL,
  GET_DOG_RESULT,
  GET_DOG_RESULT_FAIL,
  GET_DOG_RESULT_SUCCESS,
  DOWNLOAD_CERTIFICATE,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAIL,
  GET_FILE_RESULTS,
  GET_FILE_RESULTS_SUCCESS,
  GET_FILE_RESULTS_FAIL,
  SEND_CERTIFICATE,
  SEND_CERTIFICATE_SUCCESS,
  SEND_CERTIFICATE_FAIL,
  SEND_LETTER,
  SEND_LETTER_SUCCESS,
  SEND_LETTER_FAIL,
  GET_BATCH_RESULT,
  GET_BATCH_RESULT_SUCCESS,
  GET_BATCH_RESULT_FAIL,
  DOWNLOAD_LETTER,
  DOWNLOAD_LETTER_SUCCESS,
  DOWNLOAD_LETTER_FAIL,
  BATCH_PRINT,
  BATCH_PRINT_SUCCESS,
  BATCH_PRINT_FAIL,
  GET_COR_RESULT,
  GET_COR_RESULT_SUCCESS,
  GET_COR_RESULT_FAIL,
  CUSTOM_CERTIFICATE,
  CUSTOM_CERTIFICATE_SUCCESS,
  CUSTOM_CERTIFICATE_FAIL,
  DIGITAL_CERTIFICATE,
  DIGITAL_CERTIFICATE_SUCCESS,
  DIGITAL_CERTIFICATE_FAIL
} from "./actionTypes";

const initialState = {
  eventList: [],
  attendeeList: [],
  dogPoints: [],

  rankingList: [],
  overallRankingList: [],
  loading: false,
  loadingGetEventAttendee: false,
  loadingGetEventResult: false,
  loadingGetOverallResult: false,
  loadingGetClubWiseRanking: false,
  clubWiseResponse:[],
  loadingGetDogResult: false,
  certificateData: {}, 
  loadingDownloadCertificate: false,
  loadingSendCertificate: false,
  loadingSendLetter: false,
  batchRankingList: [],
  loadingBatchResult: false,
  loadingDownloadLetter: false,
  loadingBatchPrint: false,
  loadingGetCorResult: false,

  error: {
    message: "",
    code: "",
  },
  addEventResponse: {},
  loadingAddEvent: false,
  calculateResultResponse: {},
  loadingCalculateResult: false,

  fileEventList: [],
  loadingGetFileEventList: false,

  publicRankingList: [],
  loadingGetEventPublicResult: false,

  delFileResponse: {},
  loadingDelFile: false,

  calculateOverallResultRespone: {},
  loadingOverallResult: false,

  fileResultRes: [],
  loadingGetFileResults: false,

  customCertificateData: {}, 
  loadingCustomCertificate: false,

  loadingDigitalCertificate: false
};

const EventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENT:
      state = {
        ...state,
        loadingAddEvent: true
      };
      break;
    case ADD_EVENT_SUCCESS:
      state = {
        ...state,
        addEventResponse: action.payload,
        loadingAddEvent: false
      };

      break;
    case ADD_EVENT_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingAddLocation: false,
      };
      break;

    case GET_EVENTS:
      state = {
        ...state,
        loading: true
      };
      break;
    case GET_EVENTS_SUCCESS:
      state = {
        ...state,
        eventList: action.payload.eventFileList,
        loading: false
      };
      break;
    case GET_EVENTS_FAIL:
      state = {
        ...state,
        error: {
        message:  action.payload.data.message,
        },
        loading: false,
      };
      break;
    case GET_EVENT_ATTENDEE:
      state = {
        ...state,
        loadingGetEventAttendee: true
      };
      break;
    case GET_EVENT_ATTENDEE_SUCCESS:
      state = {
        ...state,
        attendeeList: action.payload.attendeeList,
        loadingGetEventAttendee: false
      };
      break;
    case GET_EVENT_ATTENDEE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingGetEventAttendee: false,
      };
      break;
    case GET_EVENT_RESULT:
      state = {
        ...state,
        loadingGetEventResult: true
      };
      break;
    case GET_EVENT_RESULT_SUCCESS:
      state = {
        ...state,
        rankingList: action.payload.rankingList,
        loadingGetEventResult: false
      };
      break;
    case GET_EVENT_RESULT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingGetEventResult: false,
      };
      break;
    case GET_OVERALL_RESULT:
      state = {
        ...state,
        loadingGetOverallResult: true
      };
      break;
    case GET_OVERALL_RESULT_SUCCESS:
      state = {
        ...state,
        overallRankingList: action.payload.rankingList,
        loadingGetOverallResult: false
      };
      break;
    case GET_OVERALL_RESULT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingGetOverallResult: false,
      };
      break;
    case CALCULATE_EVENT_RESULT:
      state = {
        ...state,
        loadingCalculateResult: true
      };
      break;
    case CALCULATE_EVENT_RESULT_SUCCESS:
      state = {
        ...state,
        calculateResultResponse: action.payload,
        loadingCalculateResult: false
      };
      break;
    case CALCULATE_EVENT_RESULT_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingCalculateResult: false,
      };
      break;
    case CALCULATE_OVERALL_RESULT:
      state = {
        ...state,
        loadingOverallResult: true
      };
      break;
    case CALCULATE_OVERALL_RESULT_SUCCESS:
      state = {
        ...state,
        calculateOverallResultRespone: action.payload,
        loadingOverallResult: false
      };
      break;
    case CALCULATE_OVERALL_RESULT_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingOverallResult: false,
      };
      break;
    case GET_EVENT_LIST:
      state = {
        ...state,
        loadingGetFileEventList: true
      };
      break;
    case GET_EVENT_LIST_SUCCESS:
      state = {
        ...state,
        fileEventList: action.payload.eventFileList,
        loadingGetFileEventList: false
      };
      break;
    case GET_EVENT_LIST_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingGetFileEventList: false,
      };
      break;
    case DEL_EVENT_FILE:
      state = {
        ...state,
        loadingDelFile: true
      };
      break;
    case DEL_EVENT_FILE_SUCCESS:
      state = {
        ...state,
        delFileResponse: action.payload,
        loadingDelFile: false
      };

      break;
    case DEL_EVENT_FILE_FAILURE:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingDelFile: false,
      };
      break;

    case GET_EVENT_PUBLIC_RESULT:
      state = {
        ...state,
        loadingGetEventPublicResult: true
      };
      break;
    case GET_EVENT_PUBLIC_RESULT_SUCCESS:
      state = {
        ...state,
        publicRankingList: action.payload.rankingList,
        loadingGetEventPublicResult: false
      };
      break;
    case GET_EVENT_PUBLIC_RESULT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingGetEventPublicResult: false,
      };
      break;

      case GET_CLUB_WISE_RANKING:
        state = {
          ...state,
          loadingGetClubWiseRanking: true
        };
        break;
      case GET_CLUB_WISE_RANKING_SUCCESS:
        state = {
          ...state,
          clubWiseResponse: action.payload.rankingList,
          loadingGetClubWiseRanking: false
        };
        break;
      case GET_CLUB_WISE_RANKING_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload.data.message,
          },
          loadingGetClubWiseRanking: false,
        };
        break;
      case GET_DOG_RESULT:
        state = {
          ...state,
          loadingGetDogResult: true
        };
        break;
      case GET_DOG_RESULT_SUCCESS:
        state = {
          ...state,
          dogPoints: action.payload.attendeeList,
          loadingGetDogResult: false
        };
        break;
      case GET_DOG_RESULT_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload.data.message,
          },
          loadingGetDogResult: false,
        };
        break;
      case GET_FILE_RESULTS:
        state = {
          ...state,
          loadingGetFileResults: true
        };
        break;
      case GET_FILE_RESULTS_SUCCESS:
        state = {
          ...state,
          fileResultRes: action.payload.eventFileList,
          loadingGetFileResults: false
        };
        break;
      case GET_FILE_RESULTS_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload.data.message,
          },
          loadingGetClubWiseRanking: false,
        };
      break;
    case DOWNLOAD_CERTIFICATE:
      state = {
        ...state,
        error: {
          message: action.payload.data,
        },
        loadingDownloadCertificate: true,
      };
      break;
    case DOWNLOAD_CERTIFICATE_SUCCESS:
      state = {
        ...state,
        certificateData: action.payload.certificateData,
        loadingDownloadCertificate: false
      };
      break;
    case DOWNLOAD_CERTIFICATE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingDownloadCertificate: false,
      };
      break;
    case SEND_CERTIFICATE:
      state = {
        ...state,
        error: {
          message: action.payload.data,
        },
        loadingSendCertificate: true,
      };
      break;
    case SEND_CERTIFICATE_SUCCESS:
      state = {
        ...state,
        message: action.payload.data,
        loadingSendCertificate: false
      };
      break;
    case SEND_CERTIFICATE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingSendCertificate: false,
      };
      break;
    case DOWNLOAD_LETTER:
        state = {
          ...state,
          error: {
            message: action.payload.data,
          },
          loadingDownloadLetter: true,
        };
        break;
    case DOWNLOAD_LETTER_SUCCESS:
        state = {
          ...state,
          letterId: action.payload.letterId,
          loadingDownloadLetter: false
        };
        break;
    case DOWNLOAD_LETTER_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingDownloadLetter: false,
      };
      break;
    case SEND_LETTER:
      state = {
        ...state,
        error: {
          message: action.payload.data,
        },
        loadingSendLetter: true,
      };
      break;
    case SEND_LETTER_SUCCESS:
      state = {
        ...state,
        message: action.payload.data,
        loadingSendLetter: false
      };
      break;
    case SEND_LETTER_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingSendLetter: false,
      };
      break;
    case GET_BATCH_RESULT:
      state = {
        ...state,
        error: {
          message: action.payload.data,
        },
        loadingBatchResult: true,
      };
      break;
    case GET_BATCH_RESULT_SUCCESS:
      state = {
        ...state,
        batchRankingList: action.payload.rankingList,
        loadingBatchResult: false
      };
      break;
    case GET_BATCH_RESULT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingBatchResult: false,
      };
      break;
    case BATCH_PRINT:
      state = {
        ...state,
        error: {
          message: action.payload.data,
        },
        loadingBatchPrint: true,
      };
      break;
    case BATCH_PRINT_SUCCESS:
      state = {
        ...state,
        batchDetails: action.payload.batchDetails,
        loadingBatchPrint: false
      };
      break;
    case BATCH_PRINT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingBatchPrint: false,
      };
      break;
    case GET_COR_RESULT:
      state = {
        ...state,
        loadingGetCorResult: true
      };
      break;
    case GET_COR_RESULT_SUCCESS:
      state = {
        ...state,
        overallRankingList: action.payload.rankingList,
        loadingGetCorResult: false
      };
      break;
    case GET_COR_RESULT_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingGetCorResult: false,
      };
      break;
    case CUSTOM_CERTIFICATE:
      state = {
        ...state,
        error: {
          message: action.payload.data,
        },
        loadingCustomCertificate: true,
      };
      break;
    case CUSTOM_CERTIFICATE_SUCCESS:
      state = {
        ...state,
        customCertificateData: action.payload.certificateData,
        loadingCustomCertificate: false
      };
      break;
    case CUSTOM_CERTIFICATE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload.data.message,
        },
        loadingCustomCertificate: false,
      };
      break;
    case DIGITAL_CERTIFICATE:
        state = {
          ...state,
          loadingDigitalCertificate: true
        };
        break;
    case DIGITAL_CERTIFICATE_SUCCESS:
        state = {
          ...state,
          certificateLink: action.payload.certificateLink,
          loadingDigitalCertificate: false
        };
        break;
    case DIGITAL_CERTIFICATE_FAIL:
        state = {
          ...state,
          error: {
            message: action.payload.data.message,
          },
          loadingDigitalCertificate: false,
        };
        break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default EventsReducer;