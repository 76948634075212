import DataTable, { createTheme } from 'react-data-table-component';
import React, { useMemo } from 'react';
import { Row, Col, Input, InputGroup, Button } from 'reactstrap';
import logo from '../assets/ckc-logo.png';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDogResult } from '../store/events/actions';
import { Circles } from "react-loader-spinner";
import { CSVLink, CSVDownload } from "react-csv";
import { red } from '@mui/material/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import { getDay } from 'rsuite/esm/utils/dateUtils';
import ContentLoader from "react-content-loader";

export const DogResult = () => {
  let allClubs = [];

  const { dogPoints, loadingGetDogResult, } = useSelector((state) => state.EventsReducer);
  const [year, setYear] = useState("2022");
  const [club, setClub] = useState(allClubs[0]);
  const [yearlyDogPoints, setYearlyDogPoints] = useState([]);
  const [dogClubPoints, setDogClubPoints] = useState([]);
  const [searchParams] = useSearchParams();
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  let dispatch = useDispatch();

  useEffect(() => {
    const startEpoch = new Date("Jan 01," + year + " 00:00:00 GMT").getTime();
    const endEpoch = new Date("Dec 31," + year + " 00:00:00 GMT").getTime();
    dispatch(getDogResult(searchParams.get('dog_number'),""));


  }, []);

  useEffect(() => {
    setDogClubPoints(dogPoints.filter(obj => {
      return obj.event_data.event_club === club;
    }));
  }, [club, dogPoints]);


  allClubs = [...new Set(dogPoints.map((item) => item.event_data.event_club))];

  function getDay(date) {
    const d = new Date(date);
    let day = d.getDay();
    return weekday[day];
  }

  const columns = useMemo(() => [

    {
      name: 'Day',
      selector: row => getDay(row.start_date),
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Level',
      sortable: true,
      wrap: true,
      grow: 2,
      cell: row => (<Row>
        <p className='dog_name'>{row.level}</p>

      </Row>

      ),
    },
    {
      name: 'Judge',
      selector: row => row.judge,
      sortable: true,
      wrap: true,
      grow: 2
    },

    {
      name: 'Points',
      selector: row => row.points,
      sortable: true,
      center: true,
      wrap: true,
    },


  ], []);


  return (

    <div className='listing-page-body'>

     
      <div className='topHeader'>
        <Row sm={12} progressPending={loadingGetDogResult}>
          <Col sm={10} className='align-self-center'>
            <h1 className='dog-name'>{dogPoints[0]?dogPoints[0].dog_data.dog_title:""}</h1>
            <p>{dogPoints[0]?dogPoints[0].dog_data.breed:""} </p>

            <p>Dog Number: {dogPoints[0]?dogPoints[0].dog_data.dog_number:""}</p>
            <p>Owner: {dogPoints[0]?dogPoints[0].contact_data.name:""}</p>

          

          </Col>
          <Col sm={2} className='align-self-center'>

            <img src={logo} alt="Logo" className='logo' />
          </Col>
        </Row>

      </div>

      <div
        style={{ paddingLeft: '120px', paddingRight: '120px', paddingTop: '20px' }}

      >
         <Row>
         {/* <Input
                id="eventYear"
                name="eventYear"
                type="select"
                onChange={(e) => setYear(e.target.value)}
                required
              >
                <option>2018</option>
                <option>2019</option>
                <option>2020</option>
                <option>2021</option>
                <option selected>2022</option>
                <option>2023</option>


              </Input> */}
          <Input
              id="clubList"
              name="clubList"
              className='clubList'
              type="select"
              onChange={(e) => setClub(e.target.value)}
              required
            >
               <option>All Clubs</option>
              {allClubs.sort().map(function (item, i) {
                return <option>{item}</option>
              })}

            </Input>

            {/* <p >{dogClubPoints[0]?dogClubPoints[0].start_date +' to ' +dogClubPoints[0].start_date:""}</p> */}
            </Row>
        {/* <p className='top-dog-stand-head'>{club}</p> */}

        <div className='divider-line1' />
            {loadingGetDogResult ? (
              <div className="loader" >
                 <ContentLoader 
                    speed={1}
                    width={'100%'}
                    height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                    viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    {Array.from({ length: 10 }, (_, index) => (
                      <rect
                        key={index}
                        x="0"
                        y={(70 + 4) * index}
                        rx="5"
                        ry="5"
                        width="100%"
                        height="70"
                      />
                    ))}
                  </ContentLoader>
              </div>
            ) : (
            <DataTable
              columns={columns}
              data={dogClubPoints.length>0?dogClubPoints:dogPoints}
              filter
              theme=""
              className='publicRankingTable'
              // progressPending={loadingGetDogResult}
              highlightOnHover
            />
          )}
      </div>

  
        </div>

  );

};
