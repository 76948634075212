const agilityClassSort = [
    "Standard 1",
    "Standard 2",
    "Standard 3",
    "Jumper with Weaves 1",
    "Jumper with Weaves 2",
    "Jumper with Weaves 3",
    "Points and Distance 1",
    "Points and Distance 2",
    "Points and Distance 3",
    "Steeplechase 1",
    "Steeplechase 2",
    "Steeplechase 3",
    "Jumps and Tunnels 1",
    "Jumps and Tunnels 2",
    "Jumps and Tunnels 3",
    "International Challenge",
    "International Challenge Jumper with Weaves",
  ];
  const agilityLevelSort = ["Novice", "Intermediate", "Excellent", "Master Excellent","Standard"];
  const agilityDivisionSort = ["Regular", "Selected", "Veterans"];
const beagleClassSort=["Males 13\"","Males 15\"","Females 13\"","Females 15\"","Mixed 13\"","Mixed 15\""];
const beaglePlacementSort=["1. First","2. Second","3. Third","4. Fourth","5. Fifth"];
const draftDogClassSort=["Novice Draft Dog","Draft Dog","Draft Dog Excellent","Brace Draft Dog","Brace Draft Dog Excellent"];
 const pointingFieldTestClassSort=["Field Dog Junior","Field Dog","Field Dog Advanced","Field Dog Excellent"];
const pointingFieldTrailClassSort=["Open Puppy","Open Derby","Amateur Shooting Dog","Open Shooting Dog","Amateur All-Age","Open All-Age",
"Amateur Limited Shooting Dog","Open Limited Shooting Dog","Amateur Limited All-Age","Open Limited All-Age",
"Shoot To Kill","Regional Amateur","Regional Open","National Amateur","National Amateur Shooting","National Open Championship","National Shooting Championship",
"National Open All-Age","Provincial Amateur","Provincial Open","Amateur Shooting Dog Championship"];
const pointingFieldTrailPlacementSort=["1. First","2. Second","3. Third","4. Fourth"];
const pointingWaterTestClassSort=["Pointing Water Test Junior","Pointing Water Test","Water Dog","Pointing Water Test Excellent","Pointing Water Test Elite"];
const retrieverFieldTrailClassSort=["Open","Limited","Special All-Age","Amateur All-Age","Owner Handler Amateur All-Age","Qualifying","Junior","National Retriever Championship Stake","National Amateur Retriever Championship Stake"];
const retrieverFieldTrailPlacementSort=["1. First","2. Second","3. Third","4. Fourth","Reserve Certificate of Merit (RCM)","Certificate of Merit (CM)"];
const retrieverHuntClassSort=["Junior Hunt","Senior Hunt","Master Hunt","Owner Handler Master Hunt","National Master Hunt"];
const retrieverHuntWCClassSort=["Working Certificate","Working Certificate Intermediate","Working Certificate Excellent"];
const spanielFieldTrailClassSort=["Puppy","Limited","Open All-Age","Amateur All-Age","Shooting Dog","National Open Championship Stake",
"National Amateur Championship Stake","Water Test","Novice"];
const spanielFieldTrailPlacementSort=["1. First","2. Second","3. Third","4. Fourth"];
const spanielHuntTestClassSort=["Working Test","Junior Hunt Test","Senior Hunt Test","Master Hunt Test"];
const trackingTestClassSort=["Tracking Dog","Tracking Dog Excellent","Urban Tracking Dog","Urban Tracking Dog Excellent"];

export function ResultSorting(data,event_category ) {
  console.log(event_category+" d fdf");
  let sortedResult;
  const sortByClass = ({ sortBy }) => {

    const sortByObject = sortBy.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    return data.sort(
      (a, b) => sortByObject[a["class"]] - sortByObject[b["class"]]
    );
  };
  const sortByClassPlacement= ({ sortBy,sortBy2 }) => {

    const sortByObject = sortBy2.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    let resultData = [];
    for (let i = 0; i < sortBy.length; i++) {
let tempSorted=[];
      let classWiseData = data.filter((el) => {
        return el.class === sortBy[i].trim();
      });
      if(classWiseData.length>0){
        tempSorted = classWiseData.sort(
        (a, b) => sortByObject[a["placement"].trim()] - sortByObject[b["placement"].trim()]
      );

      resultData = resultData.concat(tempSorted);

        }
      

    }
    console.log("sorted Results", resultData.length);

 return resultData;

  };
  const sortByClassLevelDivision = ({ sortBy,sortBy2,sortBy3 }) => {

    const sortByObject = sortBy3.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    let resultData = [];
    for (let i = 0; i < sortBy.length; i++) {

      let classWiseData = data.filter((el) => {
        return el.class === sortBy[i].trim();
      });
      if(classWiseData.length>0)
      console.log(sortBy[i],classWiseData.length);

      for (let j = 0; j < sortBy2.length; j++) {
      let tempSorted = [];

        let levelWiseData = classWiseData.filter((el) => {
          return el.level.trim() === sortBy2[j].trim();
        });

        if(levelWiseData.length>0){
        tempSorted = levelWiseData.sort(
        (a, b) => sortByObject[a["division"].trim()] - sortByObject[b["division"].trim()]
      );
      console.log(sortBy2[j],levelWiseData.length+" ="+tempSorted.length);

      resultData = resultData.concat(tempSorted);

        }

      }
      

    }
    console.log("sorted Results", resultData.length);

 return resultData;

  };
  const sortByClassPointingFieldTest = ({ sortBy }) => {

    const sortByObject = sortBy.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    return data.sort(
      (a, b) => sortByObject[a["class"].split("/")[0].trim()] - sortByObject[b["class"].split("/")[0].trim()]
    );
  };
  switch(event_category){
    case "Agility":
      sortedResult= sortByClassLevelDivision({sortBy:agilityClassSort,sortBy2:agilityLevelSort,sortBy3:agilityDivisionSort});
     break;
      case "Beagle":
      sortedResult= sortByClassPlacement({sortBy:beagleClassSort,sortBy2:beaglePlacementSort});
     break;

      case "Chase Ability":
      sortedResult= data.sort();
     break;

      case "Draft Dog Test":
        sortedResult= sortByClass({sortBy:draftDogClassSort});
        break;

        case "Earth Dog Test":
          sortedResult= sortByClass({sortBy:draftDogClassSort});
          break;

        case "Herding":
        sortedResult= sortByClass({sortBy:agilityClassSort});
        break;

        case "Lure Coursing":
        sortedResult= sortByClass({sortBy:agilityClassSort});
        break;

        case "Pointing Field Test":
        sortedResult= sortByClassPointingFieldTest({sortBy:pointingFieldTestClassSort});
        break;

        case "Pointing - Field Trial":
          sortedResult= sortByClassPlacement({sortBy:pointingFieldTrailClassSort,sortBy2:pointingFieldTrailPlacementSort});
          break;

        case "Pointing Water Test":
        sortedResult= sortByClass({sortBy:pointingWaterTestClassSort});
        break;

        case "Retriever Field Trial":
        sortedResult= sortByClassPlacement({sortBy:retrieverFieldTrailClassSort,sortBy2:retrieverFieldTrailPlacementSort});
        break;

        case "Retriever Hunt Test":
        sortedResult= sortByClass({sortBy:retrieverHuntClassSort});
        break;
        
      case "RetrieverHunt-WorkingCertTest":
        sortedResult= sortByClass({sortBy:retrieverHuntWCClassSort});
        break;

        case "Spaniel Field Trial":
          sortedResult= sortByClassPlacement({sortBy:spanielFieldTrailClassSort,sortBy2:spanielFieldTrailPlacementSort});
          break;

        case "Spaniel Hunt Test":
        sortedResult= sortByClass({sortBy:spanielHuntTestClassSort});
        break;

        case "Sprinter Test":
        sortedResult= data.sort();
        break;

        case "Tracking Test":
      sortedResult= sortByClass({sortBy:trackingTestClassSort});
      break;

      case "Scent Detection":
      sortedResult= sortByClass({sortBy:agilityClassSort});
      break;

      default:
      sortedResult= [];
      break;

    }
return sortedResult;

}


