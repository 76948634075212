import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import { Col,Card,CardHeader,CardBody,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useState, useCallback } from 'react';
  import { useAuth } from '../hooks/useAuth';
  import Grid from "@mui/material/Grid";
  import VisibilityIcon from '@mui/icons-material/Visibility';

  import {Routes, Route, useNavigate} from 'react-router-dom';
  import {useLocation} from 'react-router-dom';


function EventCatList() {

  const { user ,logout} = useAuth();
  const location = useLocation();
  const eventList=location.state.event.event;
  const topDogStandingEvents = eventList.filter(obj => {
 
     return obj.event_category==="Agility"
     ||obj.event_category==="Beagle"
     ||obj.event_category==="Lure Coursing"
     ||obj.event_category==="Herding"
     ||obj.event_category==="Pointing - Field Trial"
     ||obj.event_category==="Retriever Field Trial"
     ||obj.event_category==="Spaniel Field Trial"
     ||obj.event_category==="Sprinter"
     ;
   });

   const otherEvents = eventList.filter(obj => {
 
    return obj.event_category!=="Agility"
    &&obj.event_category!=="Beagle"
   &&obj.event_category!=="Lure Coursing"
    &&obj.event_category!=="Herding"
    &&obj.event_category!=="Pointing – Field Trial"
    &&obj.event_category!=="Retriever Field Trial"
    &&obj.event_category!=="Spaniel Field Trial"
    &&obj.event_category!=="Sprinter"
    ;
  });
  const navigate = useNavigate();

  const viewEventAttendee=(index,eventId,event)=>{
    navigate('/dashboard/event-attendees',{state:{index:index,eventId:eventId,event:event}});

  }
  const viewEventCatDetail=(index,eventFileId,event,isResult)=>{
    navigate('/dashboard/event-list',{state:{index:index,eventFileId:eventFileId,event:event,isResult:isResult}});

  }


  const columns = useMemo(()=>[

    {
      name: 'Event Category',
      selector: row => row.event_category,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Manage',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(<Row> 
        <Col>       
         <Button title='View' className='manage-event' onClick={()=>viewEventCatDetail(row.index,row.event_id,row,true)}><VisibilityIcon/></Button>
    </Col>

      </Row>
      
      ),
  
    },
  ],[],);
  
  



  const columnsOtherEvents = useMemo(()=>[

    {
      name: 'Event Category',
      selector: row => row.event_category,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Manage',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(<Row> 
        <Col>       
        <Button title='View' className='manage-event' onClick={()=>viewEventCatDetail(row.index,row.event_id,row,false)}><VisibilityIcon/></Button>

         {/* <Button title='Data' className='manage-event' onClick={()=>viewEventAttendee(row.index,row.event_file_id,row)}>Data</Button> */}

    </Col>

      </Row>
      
      ),
  
    },
  ],[],);
  


 
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <p className='page-heading'>{location.state.event.event_name}<nbsp/> 
      
      </p>

     
      <ToastContainer />

      <div className='page-body'>


      <Card
  className="my-2"
  style={{padding:0}}
 
>
  <CardHeader>Top Dog Standing Events ({topDogStandingEvents.length})</CardHeader>
<CardBody className='hotel-locations-card-body'>

       
          <DataTable
            columns={columns}
            data={topDogStandingEvents}
            highlightOnHover
            fixedHeader
            sortIcon={<FilterListIcon />}
          />
</CardBody>
      </Card>


      <Card
  className="my-2"
  style={{padding:0}}
 
>
  <CardHeader>Result-Only Events ({otherEvents.length})</CardHeader>
<CardBody className='hotel-locations-card-body'>

       
          <DataTable
            columns={columnsOtherEvents}
            data={otherEvents}
            highlightOnHover
            fixedHeader
            defaultSortAsc={false}
            defaultSortFieldId={"date"}
            sortIcon={<FilterListIcon />}
          />
</CardBody>
      </Card>

      </div>


    </div>
  );
}

export default EventCatList;
