import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUsers, activateAccount ,resetUserPassword,delUser} from '../store/users/actions';
import "../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import TextField from "@mui/material/TextField";
import { Routes, Route, useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import PasswordIcon from '@mui/icons-material/Password';
import ContentLoader from "react-content-loader";

function AllUsers() {

  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [del_id, setDelId] = useState();
  const toggle = () => {
    setModal(!modal);
  }

  const passwordToggle = (email) => {
    setResetPasswordEmail(email);
    setPasswordModal(!passwordModal);
  }
  const navigate = useNavigate();
  const { userList,error, loading, loadingActivateAccount,loadingResetUserPassword,loadingDelUser } = useSelector((state) => state.UsersReducer);

  const UserToggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const deleteToggle = (del_id) => {
    console.log(del_id);
    setDelModal(!delModal);
    setDelId(del_id);
  }

  const columns = useMemo(() => [
    {
      name: 'Name',
      sortable: true,
      wrap: true,
      cell: row => row.user_name
    },
    {
      name: 'Role',
      sortable: true,
      wrap: true,
      selector: row => {
        return row.role.split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ');
        }
    },
    {
      name: 'Email/Account',
      sortable: true,
      wrap: true,
      grow: 2,
      cell: row => row.email,
    },
    {
      name: 'Password',
      ignoreRowClick: true,
      cell: row => (<Col>
        {(userList.role==="marketing user") ?
          ('----') :
          (<Button title='Reset Password' outline onClick={()=>passwordToggle(row.email)}><PasswordIcon/></Button>
          )
        }
      </Col>
      ),
    },
    {
      name: 'Status',
      sortable: true,
      wrap: true,
      selector: row => row.status,
    },
    {
      name: 'Action',
      ignoreRowClick: true,
      grow: 1,
      cell: row => (
      <Row>
        <Col>
          <Button title='Delete' outline className='manage-event' onClick={() => deleteToggle(row.user_id)}><DeleteForeverIcon /></Button>
        </Col>
      </Row>
      ),
    },
  ], []);


  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers(user.token));
  }, []);

  const editUserData = (index, row) => {
    navigate('/event-list?id='+row.user_id, { state: { index: index, data: row } });
  }

  const moveToAddUser = () => {
    navigate('/dashboard/add-new-user');
  }

  const deleteUser = (id) => {
    const delData = {
      "user_id": id
    };
    dispatch(delUser(delData, navigate, user.token));
  }

  const handleResetPassword = async (event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const accountDataData = {
      "password": data.get("password"),
      "email": resetPasswordEmail,
    };
    dispatch(resetUserPassword(accountDataData, navigate));

  };
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p className='page-heading'>All Users<nbsp />
      {(user.adminData.role === "admin") ? (
          <span class="right-btn">
            <Button color="primary" onClick={() => moveToAddUser()}>Add New User
            </Button>
          </span>
        ) : ('')
      }
      </p>
        
      <ToastContainer />

      <div className='page-body'>
        <Card className="my-2" style={{ padding: 0 }}>
          <CardBody className='hotel-locations-card-body'>
            {loading ? (
            <div className="loader" >
              <ContentLoader 
                speed={1}
                width={'100%'}
                height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                {Array.from({ length: 10 }, (_, index) => (
                  <rect
                    key={index}
                    x="0"
                    y={(70 + 4) * index}
                    rx="5"
                    ry="5"
                    width="100%"
                    height="70"
                  />
                ))}
              </ContentLoader>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={userList}
              pagination
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              highlightOnHover
              fixedHeader
              sortIcon={<FilterListIcon />}
            />
          )}
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={passwordModal} toggle={passwordToggle} >
        <Box component="form" onSubmit={handleResetPassword}>
          <ModalHeader toggle={passwordToggle}>Change Password</ModalHeader>
          <ModalBody>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              autoFocus
              variant="filled"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {loadingResetUserPassword ? ('Loading...') : ('Set New Password')}
            </Button>{' '}
            <Button color="secondary" onClick={passwordToggle} >
              Cancel
            </Button>
          </ModalFooter>
        </Box>
      </Modal>
      <Modal isOpen={delModal} toggle={deleteToggle}>
        <ModalHeader toggle={deleteToggle}>Delete Entry</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this record?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteUser(del_id)}>
            {loadingDelUser ? ('Deleting...') : ('Yes, Delete')}
          </Button>{' '}
          <Button color="secondary" onClick={deleteToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AllUsers;
