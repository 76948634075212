import { Button } from 'reactstrap';
import React from 'react';
import { CardGroup,Card,CardTitle,CardSubtitle,CardBody,CardText ,Row, Col } from 'reactstrap';
import { useNavigate} from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PetsIcon from '@mui/icons-material/Pets';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useAuth } from "../hooks/useAuth";

function Dashboard() {
  const navigate = useNavigate();
  const { user,logout } = useAuth();

  const moveTo=(path)=>{
    navigate(path);
    
  }
  return (
    user?.adminData?.role==="marketing user"?(
    <Row>
      <Col sm="4">
        <Card body onClick={()=>navigate("/dashboard/certificates")} type='Button'>
          <Row>
          <Col sm="4">
        <Button style={{background:'#D3EBFC',borderRadius:'50%', border:0,height:'50px',width:'50px'}}> < CardGiftcardIcon/></Button>
        </Col>
        <Col sm="8">
          <CardTitle><b>Certificates</b></CardTitle>
          </Col>
        </Row>
        </Card>
      </Col>
      <Col sm="4">
        <Card body onClick={()=>navigate("/dashboard/cor-results")} type='Button'>
          <Row>
          <Col sm="4">
        <Button style={{background:'#D3EBFC',borderRadius:'50%', border:0,height:'50px',width:'50px'}}> < EmojiEventsIcon/></Button>
        </Col>
        <Col sm="8">
          <CardTitle><b>COR Results</b></CardTitle>
          </Col>
        </Row>
        </Card>
      </Col>
    </Row>
    ):(

    <Row>
      <Col sm="4">
        <Card body onClick={()=>navigate("/dashboard/allEventFiles")} type='Button'>
          <Row>
          <Col sm="4">
        <Button style={{background:'#D3EBFC',borderRadius:'50%', border:0,height:'50px',width:'50px'}}> < EmojiEventsIcon/></Button>
         </Col>
         <Col sm="8">
          <CardTitle><b>All Traditional Event Files</b></CardTitle>
          </Col>
         </Row>
        </Card>
      </Col>
      <Col sm="4">
        <Card body onClick={()=>navigate("/dashboard/add-new-file")} type='Button'>
          <Row>
          <Col sm="4">
        <Button style={{background:'#FFEAE4',borderRadius:'50%', border:0,height:'50px',width:'50px'}}> < CreateNewFolderIcon/></Button>
         </Col>
         <Col sm="8">
          <CardTitle><b>Upload New Event</b></CardTitle>
          </Col>
         </Row>
        </Card>
      </Col>

      <Col sm="4">
        <Card body onClick={()=>navigate("/dashboard/dogs")} type='Button'>
          <Row>
          <Col sm="4">
        <Button style={{background:'#FFE1E9',borderRadius:'50%', border:0,height:'50px',width:'50px'}}> < PetsIcon/></Button>
         </Col>
         <Col sm="8">
          <CardTitle><b> All Dogs</b></CardTitle>
          </Col>
         </Row>
        </Card>
      </Col>

    </Row>


    )

  );
}

export default Dashboard;
