import DataTable, { createTheme } from 'react-data-table-component';
import React, { useMemo } from 'react';
import { Row, Col, Input, InputGroup, Button, Form, FormGroup, Label } from 'reactstrap';
import { Card, CardHeader, Alert, CardBody } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorResult, downloadStandardCertificate, downloadStandardLetter, sendStandardCertificate, sendStandardLetter } from '../store/events/actions';
import { Circles } from "react-loader-spinner";
import { useAuth } from '../hooks/useAuth';
import { getPostingDates } from '../store/postingDates/actions';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Overlay, Popover } from 'react-bootstrap';
import { getGroups,getBreedsInGroup } from '../helpers/backend_helper';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ContentLoader from "react-content-loader";

export const GenerateCertificate = () => {
  const { user ,logout} = useAuth();

  const [filteredRankingList, setFilteredRankingList] = useState([]);
  var rankingForCSVAgilityHerding = [], rankingForCSV = [], rankingForCSVAgilityHerdingBreedWise = [], rankingForCSVAgilityHerdingGroupWise = [];
  const { overallRankingList, loadingGetCorResult, certificateData, loadingDownloadCertificate, loadingSendCertificate, loadingDownloadLetter, letterId, loadingSendLetter } = useSelector((state) => state.EventsReducer);
  const [breedList, setBreedList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [eventCat, setEventCat] = useState("Conformation");
  const [breed_group, setBreedGroup] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [newLoading, setNewLoading] = useState(true);
  const [downloadCerticatePayload,setDownloadCerticatePayload]=useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [iframeKey, setIframeKey] = useState(0);
  const [currentCertificate, setCurrentCertificate] = useState([{}]);
  const [letterIframeVisible, setLetterIframeVisible] = useState(false);
  const [letterUrl, setLetterUrl] = useState('');
  const [email, setEmail] = useState('');
  let new_ranking_list, filter_ranking_list;
  const { postingDatesList} = useSelector((state) => state.PostingDatesReducer);

  let dispatch = useDispatch();
  let allBreads = [];
  let allClasses = [];
  let postingDates=[];
  let beagleClasses = ["Females 13", "Females 15", "Males 13", "Males 15"];
  var no_of_export_breed_wise = 5;

  useEffect(() => {
    setNewLoading(true);
    setFilteredRankingList([]);
    const startEpoch = new Date("Jan 01," + year + " 00:00:00 GMT").getTime();
    const endEpoch = new Date("Dec 31," + year + " 00:00:00 GMT").getTime();
    dispatch(getCorResult(eventCat + "&start_year=" + startEpoch + "&end_year=" + endEpoch, user.token));
    dispatch(getPostingDates(user?user.token:""));
  }, [year, eventCat]);

  useEffect(() => {
    getAllGroups();
  }, []);

  const getAllGroups = async () => {
    let response= await getGroups();
    setAllGroups(response.group_data);
  }
  
  useEffect(() => {
    //console.log('sort data', overallRankingList);
    const filteredList = overallRankingList.filter(item => item.event_list_type === 'All Breeds');
    setFilteredRankingList(calculateRank(filteredList));
    let breedsElement = document.getElementById("breeds");
    let groupsElement = document.getElementById("groups");

    groupsElement.value = "Select Group";
    if (breedsElement !== null)
      breedsElement.value = "All Breeds";
      setNewLoading(false);
      setBreedGroup("");
  }, [overallRankingList]);

  postingDates= postingDatesList.filter(obj => {
    return obj.year === year;
  });

  useEffect(() => {
    const filteredList = overallRankingList.filter(item => item.event_list_type === 'Groups');
    setFilteredRankingList(calculateRank(filteredList.filter((el) => {
      return breedList.some((f) => {
        return f.CKCBreedName === el.new_breed;
      });
    })));
  }, [breedList]);

  const navigate = useNavigate();

  useEffect(() => {
    if(downloadCerticatePayload?.isDownloading){
      let certificateTitle = '';
      switch (eventCat) {
        case "Conformation":
          certificateTitle = "CKC TOP SHOW DOG";
          break;
        case "Obedience":
          certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
          break;
        case "Rally Obedience":
          certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
          break;
        default:
          certificateTitle = "CKC TOP " + eventCat.toUpperCase() + " DOG";
          break;
      }
      const data = {
        eventType: eventCat,
        startYear: downloadCerticatePayload.startYear,
        breedGroup: breed_group,
        resultId: downloadCerticatePayload.resultId,
        ranking: downloadCerticatePayload.ranking,
        dogTitle: downloadCerticatePayload.dogTitle,
        dogName: downloadCerticatePayload.dogName,
        certificateTitle: certificateTitle,
        certificateId: downloadCerticatePayload.certificateId,
        corResult: 1,
      }
      dispatch(downloadStandardCertificate(data, user.token));
      setCurrentCertificate([data]);
      setDownloadCerticatePayload({});
    }
  }, [downloadCerticatePayload?.isDownloading]);

  let generateCertificate = async (row)=>{
    let certificateYear = '';
    if(row.start_date != ''){
      var date = new Date(row.start_date);
      certificateYear = date.getFullYear(); 
    }
    else{
      certificateYear = year;
    }
    const data = {
      startYear: certificateYear,
      resultId: row.result_id,
      ranking: row.new_rank,
      dogTitle: row.dog_title,
      dogName: row.dog_name,
      certificateId: '',
      certificateTitle: '',
      isDownloading:true
    }
    setDownloadCerticatePayload(data);
    setSelectedRow(row);
    setIframeVisible(true);
    setEmail('');
  }

  const onFormChange = (key, value) => {
    currentCertificate[key] = value;
    setCurrentCertificate([currentCertificate]);
  }

  const updateCertificate = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const updatedCertificateData = {
      eventType: eventCat,
      breedGroup: breed_group,
      certificateTitle: data.get("certificateTitle"),
      dogTitle: data.get("dogTitle"),
      ranking: data.get("dogRank"),
      startYear: data.get("startYear"),
      certificateId: certificateData.certificateId
    }
    dispatch(downloadStandardCertificate(updatedCertificateData, user.token));
  };

  useEffect(() => {
    const appUrl = process.env.REACT_APP_APP_URL;
    const pdfUrl = appUrl+'getCertificate/';
    if (certificateData) {
      const url = pdfUrl + certificateData.certificateId;
      setCertificateUrl(url);
    }
    if(!loadingDownloadCertificate){
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [certificateData,loadingDownloadCertificate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  //Send Certificate to Owner
  const sendCertificate = async (event) => {
    event.preventDefault();
    const certificateSendData = {
      email: email,
      certificateId: certificateData.certificateId
    }
    dispatch(sendStandardCertificate(certificateSendData, user.token));
  }

  //Send Letter to Owner
  const sendLetter = async (event) => {
    event.preventDefault();
    const letterData = {
      email: email,
      letterId: letterId
    }
    dispatch(sendStandardLetter(letterData, user.token));
  }

  const generateLetter = async (row)=>{
    const data = {
      resultId: row.result_id,
      corResult: 1,
    }
    dispatch(downloadStandardLetter(data,user.token));
    setLetterIframeVisible(true);
    setEmail('');
  }

  useEffect(() => {
    const appUrl = process.env.REACT_APP_APP_URL;
    const pdfUrl = appUrl+'getLetter/';
    if (letterId) {
      const url = pdfUrl + letterId;
      setLetterUrl(url);
    }
    if(!loadingDownloadLetter){
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [letterId,loadingDownloadLetter]);

  //Close the Modal
  const closeModal = () => {
    setIframeVisible(false);
    setLetterIframeVisible(false);
  };

  const getBatchResults = async ()=>{
    const data = {
      eventType: eventCat,
      eventYear: year,
      corResult: 1
    }

    // Convert the data object to a query string
    const queryString = Object.entries(data)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    // Construct the URL with the query string
    const url = `/batch-result?${queryString}`;

    // Open a new window with the constructed URL
    window.open(url, '_blank');
  }

  allBreads = [...new Set(overallRankingList.map((item) => item.new_breed))];
  allClasses = [...new Set(overallRankingList.map((item) => item.class))];

  const formatDogName = (row) => {
    let string = row.dog_title;
    let arrayOfString = string.includes(" ") ? string.split(" ") : [string];
    let characterLength = 0;
    let lineIndex = 0;
    let line = [];
    let totalValues = arrayOfString.length;
    let formattedText = '';
    if (totalValues > 1) {
      arrayOfString.forEach((value, key) => {
          let valCharLength = value.length + 1; // add 1 for the space after each word
          characterLength += valCharLength;
          
          if (characterLength <= 31) {
              line[lineIndex] = line[lineIndex] || [];
              line[lineIndex].push(value);
          } else {
              lineIndex++;
              if (key === totalValues - 1) { // if it's the last element in the array
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(line[lineIndex - 1].pop()); // Get element from last line
                  line[lineIndex].push(value);
              } else {
                  line[lineIndex] = line[lineIndex] || [];
                  line[lineIndex].push(value);
              }
              characterLength = valCharLength; // reset character length for the new line
          }
      });

      // Join lines with <br> tag
      formattedText = line.map(line => line.join(' ')).join("\n");
    }
    else{
      formattedText = arrayOfString;
    }
    return formattedText;
  };

  const columnsObedience = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>
      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
    },
    {
      name: 'Qualif. Score',
      selector: row => row.no_of_trials,
      center: true,
      wrap: true,
    },
    {
      name: 'Av. Score',
      selector: row => row.average_score,
      center: true,
      wrap: true,
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={() => generateCertificate(row)}>
              <CardMembershipIcon/>
            </Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },
  ], []);

  const columnsConformation = useMemo(() => [
    {
      name: 'Ranking',
      selector: row => row.new_rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Dog Name & Breed',
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name' style={{ whiteSpace: 'pre-wrap' }}>{formatDogName(row)}</p>
        <p className='breed_name'>{row.new_breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      wrap: true,
    },
    {
      name: 'Total Points',
      center: true,
      selector: row => row.total_points,
      wrap: true,
      grow: 2
    },
    {
      name: 'Certificate',
      ignoreRowClick: true,
      grow:1,
      width:"100px",
      cell: row =>(
        <Row> 
          <Col sm="10">          
            <Button title='Generate Certificate' color='primary' outline className='manage-event' onClick={()=>generateCertificate(row)}><CardMembershipIcon/></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: 'Letter',
      ignoreRowClick: true,
      grow:1,
      width:"150px",
      cell: row =>(
        <Row> 
          <Col sm="5"> 
            <Button title='Generate Letter' color='primary' outline className='manage-event' onClick={() => generateLetter(row)}>
              <StickyNote2Icon/>
            </Button>
          </Col>
        </Row>
      ),
    },

  ], []);

  function calculateRank(results_array) {
    // Sort the array based on score
    results_array.sort((a, b) => b.total_points - a.total_points);

    let rank = 1;
    let lastScore = null;

    // Assign a rank to each object based on its position in the sorted array
    results_array.forEach((item, index) => {
      if (item.total_points !== lastScore) {
        rank = index + 1;
      }
      item.new_rank = rank;
      lastScore = item.total_points;
    });
    return results_array;
  }

  function handleBreedChange(e) {
    let element = document.getElementById("groups");
    element.value = "Select Group";
    console.log('breed', e.target.value);
    setBreedGroup(e.target.value);
    if (e.target.value === "All Breeds" || e.target.value === "Select") {
      const filteredList = overallRankingList.filter(item => item.event_list_type === 'All Breeds');
      setFilteredRankingList(calculateRank(filteredList));
    } else {
      const filteredList = overallRankingList.filter(item => item.event_list_type === 'Breeds');
      setFilteredRankingList(calculateRank(filteredList.filter(obj => {
        return obj.new_breed === e.target.value;
      })));
    }
  }

  const handleGroupChange = async (e) => {
    console.log('group', e.target.value);
    let breedsElement = document.getElementById("breeds");
    if (e.target.value === "Select Group") {
      if (breedsElement !== null)
          breedsElement.value = "All Breeds";
          const filteredList = overallRankingList.filter(item => item.event_list_type === 'All Breeds');
          setFilteredRankingList(calculateRank(filteredList));
          setBreedGroup("");
    } else {
      // console.log('hello');
      if (breedsElement !== null)
        breedsElement.value = "Select";
      let response= await getBreedsInGroup(e.target.value);
      // console.log("in res", response);
      setBreedList(response.breed_data);
      setBreedGroup("Group " + e.target.value);
    }
  }

  function getGroupName(breed_name) {
    let temp = allGroups.filter(obj => {
      return obj?.CKCBreedName?.toLowerCase() === breed_name?.toLowerCase();
    });
    if (temp.length > 0) {
      return temp[0].GroupName;
    } else {
      return "Unrecognized";
    }
  }
  if (overallRankingList.length > 0) {
    for (var i = 0; i < overallRankingList.length; i++) {
      let item = overallRankingList[i];
      if (item.rank > 10) {
        break;
      }
      let list = [{
        "Ranking": item.new_rank,
        "CKC Dog Number (Dog)": item.dog_number,
        "Name (Dog)": item.dog_name,
        "Dog Name with Title (Dog)": item.dog_title,
        "Breed (Dog)": item.new_breed,
        "Breed Size (Dog)": item.dog_breed_size,
        "Breed Variety (Dog)": item.dog_breed_variety,
        "CCN Breed (Dog)": item.ccn_breed,
        "Group": getGroupName(item.new_breed),
        "Number of qualifying scores": item.no_of_trials,
        "Average score": item.average_score,
        "Total points": item.total_points,
        "Full Name (Contact)": item.contact_data.name,
        "First Name (Contact)": item.contact_data.first_name,
        "Last Name (Contact)": item.contact_data.last_name,
        "CKC # (Contact)": item.contact_data.contact_number,
        "Email (Contact)": item.contact_data.email,
        "Address 1 (Contact)": item.contact_data.address,
        "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
        "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
        "Address 1: City (Contact)": item.contact_data.city,
        "Address 1: State/Province (Contact)": item.contact_data.area,
        "Address 1: Country (Contact)": item.contact_data.region,
        "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
      }];
      rankingForCSVAgilityHerding = rankingForCSVAgilityHerding.concat(list);
    }

    for (var i = 0; i < allBreads.sort().length; i++) {
      let tempList = calculateRank(overallRankingList.filter(obj => {
        return obj.new_breed === allBreads[i];
      }));

      rankingForCSVAgilityHerdingBreedWise = rankingForCSVAgilityHerdingBreedWise.concat([{
        "Breed": allBreads[i],
        "Ranking": "",
        "CKC Dog Number (Dog)": "",
        "Name (Dog)": "",
        "Dog Name with Title (Dog)": "",
        "Breed (Dog)": "",
        "Breed Size (Dog)": "",
        "Breed Variety (Dog)": "",
        "CCN Breed (Dog)": "",
        "Group": "",
        "Number of qualifying scores": "",
        "Average score": "",
        "Total points": "",
        "Full Name (Contact)": "",
        "First Name (Contact)": "",
        "Last Name (Contact)": "",
        "CKC # (Contact)": "",
        "Email (Contact)": "",
        "Address 1 (Contact)": "",
        "Address 1: Street 1 (Contact)": "",
        "Address 1: Street 2 (Contact)": "",
        "Address 1: City (Contact)": "",
        "Address 1: State/Province (Contact)": "",
        "Address 1: Country (Contact)": "",
        "Address 1: Zip/Postal Code (Contact)": "",
      }]);

      if (eventCat === "Lure Coursing" || eventCat === "Herding") {
        no_of_export_breed_wise = 10;

      } else {
        no_of_export_breed_wise = 5;
      }
      for (var j = 0; j < tempList.length; j++) {
        let item = tempList[j];
        if (item.new_rank > no_of_export_breed_wise) {
          break;
        }
        let breedRankingList = [{
          "Breed": "",
          "Ranking": item.new_rank,
          "CKC Dog Number (Dog)": item.dog_number,
          "Name (Dog)": item.dog_name,
          "Dog Name with Title (Dog)": item.dog_title,
          "Breed (Dog)": item.new_breed,
          "Breed Size (Dog)": item.dog_breed_size,
          "Breed Variety (Dog)": item.dog_breed_variety,
          "CCN Breed (Dog)": item.ccn_breed,
          "Group": getGroupName(item.new_breed),
          "Number of qualifying scores": item.no_of_trials,
          "Average score": item.average_score,
          "Total points": item.total_points,
          "Full Name (Contact)": item.contact_data.name,
          "First Name (Contact)": item.contact_data.first_name,
          "Last Name (Contact)": item.contact_data.last_name,
          "CKC # (Contact)": item.contact_data.contact_number,
          "Email (Contact)": item.contact_data.email,
          "Address 1 (Contact)": item.contact_data.address,
          "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
          "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
          "Address 1: City (Contact)": item.contact_data.city,
          "Address 1: State/Province (Contact)": item.contact_data.area,
          "Address 1: Country (Contact)": item.contact_data.region,
          "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
        }];
        rankingForCSVAgilityHerdingBreedWise = rankingForCSVAgilityHerdingBreedWise.concat(breedRankingList);
      }
    }
  }

  if (allGroups.length > 0) {
    for (var i = 1; i < 8; i++) {

      let breedList = allGroups.filter(obj => {
        return obj.GroupName === "Group " + i;
      });

      let tempList = calculateRank(overallRankingList.filter((el) => {
        return breedList.some((f) => {
          return f.CKCBreedName === el.new_breed;
        });
      }));

      //  console.log("Group "+i,tempList);

      rankingForCSVAgilityHerdingGroupWise = rankingForCSVAgilityHerdingGroupWise.concat([{
        "Group": 'Group ' + i,
        "Ranking": "",
        "CKC Dog Number (Dog)": "",
        "Name (Dog)": "",
        "Dog Name with Title (Dog)": "",
        "Breed (Dog)": "",
        "Breed Size (Dog)": "",
        "Breed Variety (Dog)": "",
        "CCN Breed (Dog)": "",
        "Number of qualifying scores": "",
        "Average score": "",
        "Total points": "",
        "Full Name (Contact)": "",
        "First Name (Contact)": "",
        "Last Name (Contact)": "",
        "CKC # (Contact)": "",
        "Email (Contact)": "",
        "Address 1 (Contact)": "",
        "Address 1: Street 1 (Contact)": "",
        "Address 1: Street 2 (Contact)": "",
        "Address 1: City (Contact)": "",
        "Address 1: State/Province (Contact)": "",
        "Address 1: Country (Contact)": "",
        "Address 1: Zip/Postal Code (Contact)": "",
      }]);

      for (var j = 0; j < tempList.length; j++) {


        let item = tempList[j];
        if (item.new_rank > 10) {
          break;
        }

        let groupRankingList = [{
          "Group": "",
          "Ranking": item.new_rank,
          "CKC Dog Number (Dog)": item.dog_number,
          "Name (Dog)": item.dog_name,
          "Dog Name with Title (Dog)": item.dog_title,
          "Breed (Dog)": item.new_breed,
          "Breed Size (Dog)": item.dog_breed_size,
          "Breed Variety (Dog)": item.dog_breed_variety,
          "CCN Breed (Dog)": item.ccn_breed,
          "Number of qualifying scores": item.no_of_trials,
          "Average score": item.average_score,
          "Total points": item.total_points,
          "Full Name (Contact)": item.contact_data.name,
          "First Name (Contact)": item.contact_data.first_name,
          "Last Name (Contact)": item.contact_data.last_name,
          "CKC # (Contact)": item.contact_data.contact_number,
          "Email (Contact)": item.contact_data.email,
          "Address 1 (Contact)": item.contact_data.address,
          "Address 1: Street 1 (Contact)": item.contact_data.address_street_1,
          "Address 1: Street 2 (Contact)": item.contact_data.address_street_2,
          "Address 1: City (Contact)": item.contact_data.city,
          "Address 1: State/Province (Contact)": item.contact_data.area,
          "Address 1: Country (Contact)": item.contact_data.region,
          "Address 1: Zip/Postal Code (Contact)": item.contact_data.zip_code,
        }];
        rankingForCSVAgilityHerdingGroupWise = rankingForCSVAgilityHerdingGroupWise.concat(groupRankingList);
      }
    }
  }
  
  return (

    <div style={{ backgroundColor: '#F1F2F4' }}>
      <Modal isOpen={iframeVisible} toggle={() => setIframeVisible(false)} size="xl">
        <ModalHeader toggle={closeModal}>Certificate for Dog Show</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8">
              {loadingDownloadCertificate ? (
                <div className="loader">
                  <ContentLoader 
                    speed={1}
                    style={{ width: '100%', height: '100%' }}
                    viewBox="0 0 700 500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#cecccc"
                  >
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                  </ContentLoader>
                </div>
              ) : (
              <iframe
                key={iframeKey}
                title="Certificate"
                src={certificateUrl}
                width="100%"
                height="600px"
                allowFullScreen
              ></iframe>
              )}
            </Col>
            <Col md="4">
              <div>
                <h5><ModeEditIcon/> Edit Certificate</h5>
                <hr></hr>
                <Form onSubmit={updateCertificate}>
                  <FormGroup row>
                    <Label for="certificateTitle" sm={4}> Certificate Title </Label>
                    <Col sm={7}>
                      <Input id="certificateTitle" name="certificateTitle" value={currentCertificate[0]?.certificateTitle} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Certificate Title" type="text" pattern="[^\d]+" title="Only letters are allowed" required/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="dogTitle" sm={4}> Dog Titles </Label>
                    <Col sm={7}>
                      <Input id="dogTitle" name="dogTitle" value={currentCertificate[0]?.dogTitle} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Dog Title" type="text" required/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="dogRank" sm={4}> Dog Rank </Label>
                    <Col sm={7}>
                      <Input id="dogRank" name="dogRank" value={currentCertificate[0]?.ranking} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Dog Rank" type="number" pattern="[0-9]*" title="Only numbers are allowed" required/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="startYear" sm={4}> Event Year </Label>
                    <Col sm={7}>
                      <Input id="startYear" name="startYear" value={currentCertificate[0]?.startYear} onChange={(e) => onFormChange(e.target.name, e.target.value)} placeholder="Enter Year" type="number" pattern="[0-9]*" title="Only numbers are allowed" required/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={{ offset: 4, size: 6}}>
                      <Button color='primary' outline type='submit'>
                        {loadingDownloadCertificate ? ('Please wait...') : ('Update')}
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
              <hr></hr>
              <div>
                <Form onSubmit={sendCertificate}>
                  <FormGroup row>
                    <Label for="email" sm={4}> Enter Email</Label>
                    <Col sm={7}>
                      <Input id="email" name="email" placeholder="Enter Email" type="email" value={email} onChange={handleEmailChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address" required/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={{ offset: 4, size: 6}}>
                    <Button color='primary' className='marginRight' outline type='submit'>
                      {loadingSendCertificate ? ('Sending...') : ('Send Certificate')}
                    </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={letterIframeVisible} toggle={() => setLetterIframeVisible(false)} size="xl">
        <ModalHeader toggle={closeModal}>Letter</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8">
              {loadingDownloadLetter ? (
                <div className="loader" >
                  <ContentLoader 
                    speed={1}
                    style={{ width: '100%', height: '100%' }}
                    viewBox="0 0 700 500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#cecccc"
                  >
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                  </ContentLoader>
                </div>
              ) : (
              <iframe
                key={iframeKey}
                title="Letter"
                src={letterUrl}
                width="100%"
                height="600px"
                allowFullScreen
              ></iframe>
              )}
            </Col>
            <Col md="4">
              <hr></hr>
              <div>
                <Form onSubmit={sendLetter}>
                  <FormGroup row>
                    <Label for="email" sm={4}> Enter Email</Label>
                    <Col sm={7}>
                      <Input id="email" name="email" placeholder="Enter Email" type="email" value={email} onChange={handleEmailChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address" required/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                      <Col sm={{ offset: 4, size: 6}}>
                      <Button color='primary' className='marginRight' outline type='submit'>
                        {loadingSendLetter ? ('Sending...') : ('Send Letter')}
                      </Button>
                      </Col>
                  </FormGroup>
                </Form>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div style={{ backgroundColor: '#F1F2F4' }}>
        <p className='page-heading'>C/O/R Results <nbsp/> 
          <span class="right-btn">
            <Button color="primary" onClick={getBatchResults}>Generate Batch
            </Button>
          </span>
        </p>
      </div>
      <div className='page-body'>
        <Card className="my-2" style={{ padding: 0 }}>
          <CardHeader>
            <InputGroup sm={12}>
              <Col sm={3} className='marginRight'>
                <Input id="eventCat" name="eventCat" type="select" onChange={(e) => setEventCat(e.target.value)} required>
                  <option value={"Conformation"}>Conformation</option>
                  <option value={"Obedience"}>Obedience</option>
                  <option value={"Rally Obedience"}>Rally Obedience</option>                  
                </Input>
              </Col>
              <Col sm={2} className='marginRight'>
                <Input id="eventYear" name="eventYear" type="select" onChange={(e) => setYear(e.target.value)} required>
                  {[...Array(3)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                  })}
                </Input>
              </Col>
              <Col sm={3} className='marginRight'>
                <Input
                  sm={3}
                  id='breeds'
                  type='select'
                  name='breeds'
                  onChange={handleBreedChange}
                  placeholder='Dog Breed' >
                  <option>Select</option>
                  <option selected>All Breeds</option>
                  {allBreads.sort().map(function (item, i) {
                    return <option>{item}</option>
                  })}
                </Input>
              </Col>
              <Col sm={3} className='marginRight'>
                <Input
                  sm={3}
                  id='groups'
                  type='select'
                  name='groups'
                  onChange={handleGroupChange}
                  placeholder='Dog Group' >
                  <option>Select Group</option>
                  <option value={1}>Group 1 - Sporting Group</option>
                  <option value={2}>Group 2 - Hound Group</option>
                  <option value={3}>Group 3 - Working Group</option>
                  <option value={4}>Group 4 - Terrier Group</option>
                  <option value={5}>Group 5 - Toy Group</option>
                  <option value={6}>Group 6 - Non-Sporting Group</option>
                  <option value={7}>Group 7 - Herding Group</option>
                </Input>
              </Col>
            </InputGroup>
          </CardHeader>
          <CardBody className='event-card-body'>
              {loadingGetCorResult ? (
              <div className="loader" >
                 <ContentLoader 
                    speed={1}
                    width={'100%'}
                    height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                    viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    {Array.from({ length: 10 }, (_, index) => (
                      <rect
                        key={index}
                        x="0"
                        y={(70 + 4) * index}
                        rx="5"
                        ry="5"
                        width="100%"
                        height="70"
                      />
                    ))}
                  </ContentLoader>
              </div>
            ) : (
            <DataTable
                columns={eventCat === "Obedience" || eventCat === "Rally Obedience" ? (columnsObedience) : (columnsConformation)}
                data={calculateRank(filteredRankingList)}
                filter
                theme=""
                pagination
                paginationRowsPerPageOptions={[50,100,200,300]}
                paginationPerPage={50}
                highlightOnHover
                defaultSortAsc={false}
                defaultSortFieldId={"totalPoints"}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default GenerateCertificate;
