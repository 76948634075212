import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Card,  CardBody } from 'reactstrap';

import "../styles.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LaunchIcon from '@mui/icons-material/Launch';

function PublicUrls() {
   const REACT_APP_FRONT_URL = process.env.REACT_APP_FRONT_URL;


const publicUrlList=[
  {
    "type":"Overall Ranking",
    "url":REACT_APP_FRONT_URL+"overall_ranking"
  },
  {
    "type":"Agility",
    "url":REACT_APP_FRONT_URL+"ranking?type=Agility"
  },
  {
    "type":"Beagle",
    "url":REACT_APP_FRONT_URL+"ranking?type=Beagle"
  },  {
    "type":"Lure Coursing",
    "url":REACT_APP_FRONT_URL+"ranking?type=Lure Coursing"
  },  {
    "type":"Herding",
    "url":REACT_APP_FRONT_URL+"ranking?type=Herding"
  },  {
    "type":"Pointing Field Trial",
    "url":REACT_APP_FRONT_URL+"ranking?type=Pointing - Field Trial"
  },  {
    "type":"Retriever Field Trial",
    "url":REACT_APP_FRONT_URL+"ranking?type=Retriever Field Trial"
  },  {
    "type":"Spaniel Field Trial",
    "url":REACT_APP_FRONT_URL+"ranking?type=Spaniel Field Trial"
  }
];

  const columns = useMemo(() => [
    {
      name: 'Type',
      selector: row => row.type,
      wrap: true,
    },
    {
      name: 'Public URL',
      selector: row => row.url,
      grow:4,
      center:true,

    },
    {
      name:'Visit',
      wrap: true,
      cell: row => (<Row>
                   <Button color='primary' outline  target="_blank" size='sm' href={row.url}><LaunchIcon/></Button>

      </Row>

      ),
    }
     
  ], []);

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <div className='page-heading'>

        <Row>
          <Col sm="3">
            Public Standing URL's
          </Col>
          <Col sm="2"></Col>
        
        </Row>
      </div>


      <ToastContainer />

      <div className='page-body'>
        <Card className="my-2" style={{ padding: 0 }}>
          <CardBody className='event-card-body'>
              <DataTable
                columns={columns}
                data={publicUrlList}
                theme=""
                fixedHeader
                sortIcon={<FilterListIcon />}
              />
          </CardBody>
        </Card>
      </div>


    </div>
  );
}

export default PublicUrls;
