import { Button, CardSubtitle, CardTitle, Row ,Badge} from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Card, CardHeader, Alert, CardBody } from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEventResult, calculateEventResult } from '../store/events/actions';
import "../styles.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import LaunchIcon from '@mui/icons-material/Launch';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ContentLoader from "react-content-loader";

function EventResult() {


  const navigate = useNavigate();
  const { rankingList, loadingGetEventResult, loadingCalculateResult } = useSelector((state) => state.EventsReducer);
  const location = useLocation();
  const { user, logout } = useAuth();

  const REACT_APP_FRONT_URL = process.env.REACT_APP_FRONT_URL;

  const columns = useMemo(() => [
    {
      name: 'Rank',
      selector: row => row.rank,
      sortable: true,
      wrap: true,
      center: true,
    },
    {
      name: 'Dog Title',
      selector: row => row.dog_title,
      sortable: true,
      wrap: true,
      grow: 3

    },
    {
      name: 'Dog Number',
      selector: row => row.dog_number,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Breed',
      selector: row => row.breed,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      id: 'totalPoints',
      name: 'Total Points',
      selector: row => row.total_points,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Average Score',
      selector: row => row.average_score,
      sortable: true,
      wrap: true,

    }
  ], []);

  const otherColumns = useMemo(() => [
    {
      name: 'Rank',
      selector: row => row.rank,
      sortable: true,
      wrap: true,
      center: true,
    },
    {
      name: 'Dog Title',
      selector: row => row.dog_title,
      sortable: true,
      wrap: true,
      grow: 3

    },
    {
      name: 'Dog Number',
      selector: row => row.dog_number,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Breed',
      selector: row => row.breed,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      id: 'totalPoints',
      name: 'Total Points',
      selector: row => row.total_points,
      sortable: true,
      wrap: true,
    }
  ], []);





  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventResult(location.state.eventId, user.token));

  }, []);


  const submitData = async () => {
    const data = {
      "event_id": location.state.eventId,
    };
    console.log(data);
    dispatch(calculateEventResult(data, navigate, user.token));
  };



  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <div className='page-heading'>
          
<Row>
<Col sm="3">
        Event Result
        </Col>
        <Col sm="2"></Col>
        <Col sm="6" className='class-col'>
        {rankingList.length === 0 ? (
          <Button  title='Result' color='primary' outline size='sm' onClick={() => submitData()}> {loadingCalculateResult ? ('Calculating...') : ('Calculate Result')}</Button>

        ) : (

          <Row>  
            <Button color='primary' outline  target="_blank" size='sm' href={REACT_APP_FRONT_URL+"listing?type="+location.state.event.event_category+"&id="+location.state.eventId}>Top Dogs {location.state.event.event_category} <LaunchIcon/></Button>
          </Row>

          )}
        </Col>
        </Row>
        </div>


      <ToastContainer />

      <div className='page-body'>
      
        {loadingCalculateResult ? (

          <Alert color="primary">
            Please wait while we are calculating Points. It can take few minutes...
          </Alert>

        ) :
          ('')}
        <Card
          className="my-2"
          style={{ padding: 0 }}

        >
          <CardHeader>
            <CardTitle>{location.state.event.name + " - " + location.state.event.event_category + " - " + location.state.event.club + " ( " + location.state.event.start_date + " to " + location.state.event.end_date + " ) "}
            </CardTitle>
            </CardHeader>
          <CardBody className='event-card-body'>

            {loadingGetEventResult ? (
              <div className="loader" >
                <ContentLoader 
                  speed={1}
                  width={'100%'}
                  height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
                  viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <rect
                      key={index}
                      x="0"
                      y={(70 + 4) * index}
                      rx="5"
                      ry="5"
                      width="100%"
                      height="70"
                    />
                  ))}
                </ContentLoader>
              </div>
            ) : (
              <DataTable
                columns={location.state.event.event_category==="Agility"?columns:otherColumns}
                data={rankingList}
                theme=""
                pagination
                highlightOnHover
                defaultSortAsc={false}
                defaultSortFieldId={"totalPoints"}
                fixedHeader
                sortIcon={<FilterListIcon />}
              />
            )}
          </CardBody>
        </Card>
      </div>


    </div>
  );
}

export default EventResult;
