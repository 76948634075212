import { Button, Row, Input, InputGroup, } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFileResults } from '../../../store/events/actions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';

import { CSVLink, CSVDownload } from "react-csv";

import { useAuth } from '../../../hooks/useAuth';
import Grid from "@mui/material/Grid";
import file_result_banner from '../../../assets/file_result_banner.png';
import "../../../styles.css";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EventFileResultsAttendee from './EventFileResultsAttendee';

function EventFileResults() {


  const navigate = useNavigate();
  const { fileResultRes, loadingGetFileResults } = useSelector((state) => state.EventsReducer);
  const location = useLocation();
  const { user, logout } = useAuth();
  const [currentRow, setCurrentRow] = useState(null);

 
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [eventCat, setEventCat] = useState("Agility");
  const eventColumns = useMemo(() => [

    {
      grow:1,
    
      cell: row => (<Row>
        <Col>
      
        <p className='view_result'>View Result</p>

    
        </Col>

      </Row>

      ),

    },
//     {
// grow:2,
//       wrap: true,
//       cell: row => (<Row style={{padding:"10px"}}>
//         <Col>
//         <p className='result_head'>Judge</p>
//        <p className='result_data'>{row[0]?.attendees[0]?.judge}</p>


//         </Col>

//       </Row>

//       ),
//     },
    {

      wrap: true,
      cell: row => (<Row style={{padding:"10px"}}>
        <Col>
        <p className='result_head'>Event Number</p>
       <p className='result_data'>{row[0].ShowID}</p>


        </Col>

      </Row>

      ),
    },
    {
      wrap: true,
      cell: row => (<Row style={{padding:"10px"}}>
        <Col>
        <p className='result_head'>Start Date</p>
       <p className='result_data'>{(row[0].start_date).split(" ")[0]}</p>


        </Col>

      </Row>

      ),
    },
    {
      grow:2,
      
      wrap: true,
      cell: row => (<Row style={{padding:"10px"}}>
        <Col>
        <p className='result_head'>Location</p>
       <p className='result_data'>{row[0].attendees[0]?.event_data?.venue}</p>


        </Col>

      </Row>

      ),
    },
    
    {
      wrap: true,
      grow:3,

      cell: row => (<Row style={{padding:"10px"}}>
        <Col>
        <p className='result_head'>Club</p>
       <p className='result_data'>{row[0].club}</p>


        </Col>

      </Row>

      ),
    },
    
 
  

  ], [],);






  let dispatch = useDispatch();

  useEffect(() => {
    const startEpoch = new Date("Jan 01," + year + " 00:00:00 GMT").getTime();
    const endEpoch = new Date("Dec 31," + year + " 00:00:00 GMT").getTime();
    dispatch(getFileResults(eventCat+"&start_year=" + startEpoch + "&end_year=" + endEpoch));


  }, [eventCat,year]);
  
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <img src={file_result_banner} class="file_result_banner" />




      <ToastContainer />

    


      <div className='result-body'>
      <p className='top-dog-stand-head'>Filters</p>

      <Row style={{marginBottom:"50px"}}>
            <InputGroup sm={12}>


              <Input
                id="eventCat"
                name="eventCat"
                type="select"
                onChange={(e) => setEventCat(e.target.value)}
                required
              >
                <option>Agility</option>
                <option  value={"Beagle"}>Beagle Field Trial</option>
                <option value={"Chase Ability"}>Chase Ability Test</option>
                <option >Draft Dog Test</option>
                <option >Earth Dog Test</option>
                <option disabled >Herding</option>

                
                <option disabled> Lure Coursing</option>
                <option >Pointing Field Test</option>

                <option  value={"Pointing - Field Trial"}>Pointing Field Trial</option>

                <option >Pointing Water Test</option>

                <option >Retriever Field Trial</option>
                <option disabled>Retriever Hunt Test</option>
                <option value={"RetrieverHunt-WorkingCertTest"}>Retriever Working Certificate Test</option>
                <option >Spaniel Field Trial</option>
                <option>Spaniel Hunt Test</option>
                <option>Sprinter Test</option>
                <option>Tracking Test</option>
                <option disabled>Scent Detection</option>

              </Input>
              {/* <Input
                id="eventYear"
                name="eventYear"
                type="select"
                onChange={(e) => setYear(e.target.value)}
                required
              >
                <option selected>2021</option>

                {eventCat!=="Spaniel Field Trial"?<option >2022</option>:""}
                
                <option selected>2023</option>
              </Input> */}
              <Input id="eventYear" name="eventYear" type="select" onChange={(e) => setYear(e.target.value)} required>
                {[...Array(4)].map((_, index) => {
                  const year = new Date().getFullYear() - index;
                  return <option key={year} selected={year.toString() === year ? "selected" : ""}>{year}</option>;
                })}
              </Input>
              </InputGroup>
          </Row>

        <p className='top-dog-stand-head'>Events</p>


        {/* {fileResultRes.map(function (item, i) {
          return (
            <Card
              className="my-2"
              style={{ padding: 10 }}

            >
              <Row sm={12}>
                <Col sm={4}>
                  <p className='result_head'>Club</p>
                  <p className='result_data'>{item.club}</p>

                </Col>
                <Col sm={2}>

                  <p className='result_head'>Event</p>
                  <p className='result_data'>{item.event_type}</p>


                </Col>
                <Col sm={2}>

                  <p className='result_head'>Location</p>
                  <p className='result_data'>{item.attendees[0].event_data.venue}</p>


                </Col>
                <Col sm={2}>

                  <p className='result_head'>Start Date</p>
                  <p className='result_data'>{(item.start_date).split(" ")[0]}</p>


                </Col>

                <Col sm={1} >

                  <p className='view_result'>View Result</p>


                </Col>
              </Row>

             

            </Card>

          )
        })} */}



            <DataTable
              columns={eventColumns}
              data={fileResultRes}
              theme=""
              progressPending={loadingGetFileResults}
              expandableRows
              expandOnRowClicked
              onRowClicked={(row) => setCurrentRow(row)}
              onRowExpandToggled={(bool, row) => setCurrentRow(row)}  
              expandableRowsComponent={EventFileResultsAttendee}
              highlightOnHover
              className='file_result'
              sortIcon={<FilterListIcon />}
            />

 
      </div>


    </div>
  );
}

export default EventFileResults;
