import { delItem, get, put, putWithoutToken } from "./api_helper";
import * as url from "./url_helper";
import { post, postEvent, postLogin } from "./api_helper";

export const login = (data) => postLogin(url.LOGIN, data);

export const forgotPassword = (data) => post(url.FORGOT_PASSWORD, data);
export const resetPassword = (data) => putWithoutToken(url.RESET_PASSWORD, data);
export const changePassword = (data, token) => put(url.CHANGE_PASSWORD, data, token);

export const getEvents = (data, token) => post(url.GET_EVENTS, data, token);
export const addEvent = (data, token) => postEvent(url.ADD_EVENT, data, token);
export const getEventAttendees = (eventId, token) => get(
  url.GET_EVENT_ATTENDEES + "/" + eventId, token);
export const delEventFile = (data, token) => delItem(url.DEL_EVENT_FILE, data, token);

export const getEventResult = (eventId, token) => get(
  url.GET_EVENT_RESULT + "/?event_id=" + eventId, token);
export const calculateEventResult = (data, token) => post(url.CALCULATE_EVENT_RESULT, data, token);
export const calcualteOverallResult = (data, token) => post(url.CALCULATE_OVERALL_RESULT, data, token);
export const getOverallResult = (eventId, token) => get(
  url.GET_OVERALL_RESULT + "/?event_category=" + eventId, token);
  export const getClubWiseRanking = (eventId) => get(
    url.GET_CLUB_WISE_RANKING + eventId);
  
export const getDogs = (token) => get(url.GET_DOGS, token);
export const delDog = (data, token) => delItem(url.DEL_DOG, data, token);
export const editDog = (data, token) => put(url.GET_DOGS, data, token);
export const getGroups = () => get(url.GET_DOGS+"/groups");
export const getBreedsInGroup = (groupId) => get(url.GET_DOGS+"/breed-in-group/"+groupId);

export const getEventList = (eventFileId, token) => get(
  url.GET_EVENTS + "/" + eventFileId, token);

export const getEventPublicResult = (eventId) => get(
  url.GET_EVENT_PUBLIC_RESULT + "/?event_id=" + eventId);   

  export const getDogResult = (dog_number, token) => get(
    url.GET_DOG_RESULT + "/" + dog_number, token);



export const getPostingDates = (token) => get(url.POSTING_DATES, token);
export const savePostingDates = (data, token) => post(url.POSTING_DATES, data, token);

export const downloadStandardCertificate = (data, token) => post(url.DOWNLOAD_STANDARD_CERTIFICATE, data, token);
export const downloadStandardLetter = (data, token) => post(url.DOWNLOAD_STANDARD_LETTER, data, token);

export const getUsers = (token) => get(url.GET_USERS, token);
export const delUser = (data, token) => delItem(url.DEL_USER, data, token);
export const activateAccount = (data) => postLogin(url.ACTIVATE_ACCOUNT,data);
export const resetUserPassword = (data) => postLogin(url.RESET_USER_PASSWORD,data);

export const getFileResults = (data, token) => get(
  url.GET_EVENT_RESULT_DISPLAY + "/results?event_category=" + data, token);
export const getEventResultsDisplayFiles = (token) => get(url.GET_EVENT_RESULT_DISPLAY, token);
export const addEventResultDisplay = (data, token) => postEvent(url.ADD_EVENT_RESULT_DISPLAY_FILE, data, token);
export const delEventResultsDisplayFile = (data, token) => delItem(url.GET_EVENT_RESULT_DISPLAY, data, token);

export const sendStandardCertificate = (data, token) => get(url.SEND_STANDARD_CERTIFICATE+ "/?certificateId=" + data.certificateId + "&email=" + data.email, token);

export const sendStandardLetter = (data, token) => get(url.SEND_STANDARD_LETTER+ "/?letterId=" + data.letterId + "&email=" + data.email, token);
export const getBatchResult = (data, token) => post(url.GET_BATCH_RESULT, data, token);
export const batchPrint = (data, token) => post(url.BATCH_PRINT, data, token);
export const addCOREvent = (data, token) => postEvent(url.ADD_COR_EVENT, data, token);
export const getCorResult = (eventId, token) => get(url.GET_COR_RESULT + "/?event_category=" + eventId, token);

export const createCustomCertificate = (data, token) => post(url.CREATE_CUSTOM_CERTIFICATE, data, token);

export const downloadDigitalCertificate = (data) => post(url.DOWNLOAD_DIGITAL_CERTIFICATE, data);

export const getBreedGroups = (data, token) => get(url.GET_BREED_GROUP + "/?breed_year=" + data, token);
export const addBreedGroup = (data, token) => post(url.ADD_BREED_GROUP, data, token);
export const editBreedGroup = (data, token) => post(url.EDIT_BREED_GROUP, data, token);
export const delBreedGroup = (data, token) => delItem(url.DEL_BREED_GROUP, data, token);
export const uploadBreedGroup = (data, token) => postEvent(url.UPLOAD_BREED_GROUP, data, token);

export const editPostingDates = (data, token) => post(url.EDIT_POSTING_DATES, data, token);
