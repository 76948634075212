import {
  GET_EVENT_DISPLAY_RESULT_FILES,
  GET_EVENT_DISPLAY_RESULT_FILES_FAIL,
  GET_EVENT_DISPLAY_RESULT_FILES_SUCCESS,
  ADD_DISPLAY_EVENT_FILE,
  ADD_DISPLAY_EVENT_FILE_FAILURE,
  ADD_DISPLAY_EVENT_FILE_SUCCESS,
  DEL_DISPLAY_EVENT_FILE,
  DEL_DISPLAY_EVENT_FILE_SUCCESS,
  DEL_DISPLAY_EVENT_FILE_FAILURE
} from "./actionTypes";

export const getEvents= (token) => {
  return {
    type: GET_EVENT_DISPLAY_RESULT_FILES,
    payload:token
  };
};

export const getEventsSuccess = (eventList) => {
  return {
    type: GET_EVENT_DISPLAY_RESULT_FILES_SUCCESS,
    payload: eventList,
  };
};

export const getEventsFail = (error) => {
  return {
    type:GET_EVENT_DISPLAY_RESULT_FILES_FAIL,
    payload: error,
  };
};

export const addEvent = (data,navigate,token) => {
  return {
    type: ADD_DISPLAY_EVENT_FILE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const addEventSuccess = (addEventResponse) => {
  return {
    type: ADD_DISPLAY_EVENT_FILE_SUCCESS,
    payload:addEventResponse,
  };
};

export const addEventFailure = (error) => {
  return {
    type: ADD_DISPLAY_EVENT_FILE_FAILURE,
    payload:error,
  };
};



// delete event file


export const delEventFile = (data,navigate,token) => {
  return {
    type: DEL_DISPLAY_EVENT_FILE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delEventFileSuccess = (response) => {
  return {
    type: DEL_DISPLAY_EVENT_FILE_SUCCESS,
    payload:response,
  };
};

export const delEventFileFailure = (error) => {
  return {
    type: DEL_DISPLAY_EVENT_FILE_FAILURE,
    payload:error,
  };
};