//Post

export const LOGIN = "/api/v1/admin/login";
export const FORGOT_PASSWORD = "/api/v1/admin/forgot-password";
export const RESET_PASSWORD = "/api/v1/user/create-password";
export const CHANGE_PASSWORD = "/api/v1/admin/change-password";

export const GET_EVENTS="/api/v1/event";
export const ADD_EVENT = "/api/v1/event/upload";

export const DEL_EVENT_FILE="/api/v1/event";

export const GET_EVENT_ATTENDEES = "/api/v1/attendee";
export const GET_EVENT_RESULT = "/api/v1/result";
export const CALCULATE_EVENT_RESULT = "/api/v1/result/calculate";
export const CALCULATE_OVERALL_RESULT = "/api/v1/result/calculate-yearly";
export const GET_OVERALL_RESULT = "/api/v1/result/public/yearly";
export const GET_CLUB_WISE_RANKING = "/api/v1/result/public/clubWise";


export const GET_DOGS="/api/v1/dog";
export const DEL_DOG="/api/v1/dog";


export const GET_EVENT_PUBLIC_RESULT = "/api/v1/result/public";


export const GET_DOG_RESULT = "/api/v1/attendee/dog_points";


export const POSTING_DATES="/api/v1/postingDates";

export const DOWNLOAD_STANDARD_CERTIFICATE="/api/v1/certificate/generate-certificate";
export const DOWNLOAD_STANDARD_LETTER="/api/v1/certificate/generate-letter";

export const GET_USERS="/api/v1/user";
export const DEL_USER="/api/v1/user/delete-user";
export const ACTIVATE_ACCOUNT="/api/v1/user/create-user";
export const RESET_USER_PASSWORD = "/api/v1/user/update-password";

export const GET_EVENT_RESULT_DISPLAY="/api/v1/eventResultsDisplay";
export const ADD_EVENT_RESULT_DISPLAY_FILE = "/api/v1/eventResultsDisplay/upload";

export const SEND_STANDARD_CERTIFICATE="/api/v1/certificate/send-certificate";
export const SEND_STANDARD_LETTER="/api/v1/certificate/send-letter";

export const GET_BATCH_RESULT="/api/v1/certificate/batch-result";
export const BATCH_PRINT="/api/v1/certificate/batch-print";

export const ADD_COR_EVENT = "/api/v1/cor/upload";
export const GET_COR_RESULT = "/api/v1/cor/results";

export const CREATE_CUSTOM_CERTIFICATE="/api/v1/certificate/generate-custom-certificate";

export const DOWNLOAD_DIGITAL_CERTIFICATE="/api/v1/certificate/digital-certificate";

export const GET_BREED_GROUP = "/api/v1/dog/breed-groups";
export const ADD_BREED_GROUP = "/api/v1/dog/add-breed-group";
export const EDIT_BREED_GROUP = "/api/v1/dog/edit-breed-group";
export const DEL_BREED_GROUP = "/api/v1/dog/delete-breed-group";
export const UPLOAD_BREED_GROUP = "/api/v1/dog/upload-breed-group";

export const EDIT_POSTING_DATES = "/api/v1/postingDates/edit-posting-date";
