import { Button, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import { Col,Card,CardHeader,CardBody,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEvents,delEventFile } from '../store/events/actions';
import "../styles.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/useAuth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import {Routes, Route, useNavigate} from 'react-router-dom';
import ContentLoader from "react-content-loader";

createTheme('data-table', {
  text: {
    primary: '#000',
    secondary: '#2aa198',
  },
  background: {
    default: '#FFF',
  },
  context: {
    background: '#cb4b16',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

function AllEventFiles() {

  const { user,logout } = useAuth();
  const [modal, setModal] = useState(false);
  const [fileId, setId] = useState();

  const toggle = (fileId) => {
    setModal(!modal);
    setId(fileId);
  }
  const navigate = useNavigate();
  const { eventList, loading,delFileResponse,loadingDelFile,error } = useSelector((state) => state.EventsReducer);

  const viewEventDetail=(index,event)=>{
    navigate('/dashboard/event-categories',{state:{index:index,event:event}});
  }

  const moveToUploadNewFile = () => {
    navigate('/dashboard/add-new-file');
  }

  if(!loading&&error.message==="Unauthorised Access"){
    //logout();
  }
  const columns = useMemo(()=>[
  
    {
      name: 'File Name',
      selector: row => row.event_name,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Event Year',
      selector: row => row.event_year,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      id:'date',
      name: 'File Created Date',
      selector: row => row.created_at,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Manage',
      ignoreRowClick: true,
      grow:1,
      width:"200px",
      cell: row =>(<Row> 
      { row.status==="completed" ? (
          <Col>
            <Button title='View' color='primary' outline className='manage-event' onClick={()=>viewEventDetail(row.index,row)}><VisibilityIcon/></Button>       
            <Button title='Delete' outline  className='manage-event' onClick={()=>toggle(row.event_file_id)}><DeleteForeverIcon/></Button>
          </Col>
        ):(
          <Col>       
            <Button title='We are processing this file. Please wait!' color='primary'  className='manage-event' size='sm' outline><AssignmentLateIcon/> In Progress</Button>
            {/* <Button title='Delete' outline  className='manage-event' onClick={()=>toggle(row.event_file_id)}><DeleteForeverIcon/></Button> */}
          </Col>
        )
      }
      </Row>
      ),
    },
  ],[],);
  
  
  const deleteFile = (fileId) => {
    const delData = {
      "event_file_id": fileId
    };
    console.log(delData);
    dispatch(delEventFile(delData, navigate, user.token));
  }



  let dispatch = useDispatch();

  useEffect(() => {
    const data = {
      corEventFiles: 0
    }
    dispatch(getEvents(data, user.token));

    let interval = setInterval(()=>dispatch(getEvents(data, user.token)),60000);
    return () => {
      clearInterval(interval);
    };

  }, []);

 
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>

      <p className='page-heading'>All Traditional Event Files <nbsp/> 
        <span class="right-btn">
        <Button color="primary" onClick={() => moveToUploadNewFile()}>Add New File
        </Button>
        </span>
      </p>

     
      <ToastContainer />

      <div className='page-body'>


      <Card
  className="my-2"
  style={{padding:0}}
 
>
<CardBody >

        {loading ? (
          <div className="loader" >
            <ContentLoader 
              speed={1}
              width={'100%'}
              height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
              viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {Array.from({ length: 10 }, (_, index) => (
                <rect
                  key={index}
                  x="0"
                  y={(70 + 4) * index}
                  rx="5"
                  ry="5"
                  width="100%"
                  height="70"
                />
              ))}
            </ContentLoader>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={eventList}
            theme=""
            pagination
            paginationRowsPerPageOptions={[50,100]}
            paginationPerPage={50}
            highlightOnHover
            fixedHeader
            defaultSortAsc={false}
            defaultSortFieldId={"date"}
            sortIcon={<FilterListIcon />}
          />
        )}
</CardBody>
      </Card>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Entry</ModalHeader>
        <ModalBody>
          Are you sure you want to Delete this Event File?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteFile(fileId)}>
            {loadingDelFile ? ('Deleting...') : ('Yes, Delete')}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

export default AllEventFiles;
