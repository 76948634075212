import { BasicPage } from "../components/BasicPage";
import Home from "@mui/icons-material/Home";
import { Button } from "@mui/material";

export const Welcome = () => {
   
  return (
   
    <div style={{backgroundColor:'#7386D5',height:'400px',padding:'100px',display: 'flex',
    justifyContent: 'center'}}>
    
      <div style={{color:'#FFF'}}>
        <h1>Welcome to Dashboard</h1>
      </div>
     
    </div>
      );
    
};
