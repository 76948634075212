import { takeLatest, put, call } from "redux-saga/effects";

import { GET_USERS,ACTIVATE_ACCOUNT,RESET_USER_PASSWORD,DEL_USER} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFail,
  activateAccountSuccess,
  activateAccountFailure,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  delUserFailure,
  delUserSuccess
  
} from "./actions";

import { getUsers,activateAccount,resetUserPassword,delUser} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* onGetUsers({token}) {
  try {
    const response = yield call(getUsers,token);
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    
    yield put(getUsersFail(error.response));
  }
}


function* onActivateAccount({ payload: data,navigate }) {
  try {
    const response = yield call(activateAccount,data);
    console.log(response.data);
    toast(response.message);
    if(response.data.statusCode===200){
      navigate("/dashboard/allUsers");
    }
    yield put(activateAccountSuccess(response.data));
  } catch (error) {
    yield put(activateAccountFailure(error.response));
  }
}
function* onResetUserPassword({ payload: data,navigate }) {
  try {
    const response = yield call(resetUserPassword,data);
    toast(response.message);
    if(response.data.statusCode===200){
      navigate(0);
    }
    yield put(resetUserPasswordSuccess(response.data));
  } catch (error) {
  toast(error.response.data.data);
    yield put(resetUserPasswordFailure(error.response));
  }
}
function* onDelUser({ payload: data,navigate,token }) {
  try {
    const response = yield call(delUser,data,token);
    toast(response.data);
    if(response.statusCode===200){
      navigate(0);
    }
    yield put(delUserSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delUserFailure(error.response));
  }
}


function* SecSaga() {

  yield takeLatest(GET_USERS, onGetUsers);
  yield takeLatest(ACTIVATE_ACCOUNT, onActivateAccount);
  yield takeLatest(RESET_USER_PASSWORD, onResetUserPassword);
  yield takeLatest(DEL_USER, onDelUser);

}

export default SecSaga;
