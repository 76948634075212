import { Row } from "reactstrap";
import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Col,
  Form,
  Card,
  FormText,
  FormGroup,
  Label,
  Input,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../../styles.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useCallback } from "react";
import { addShowDocument } from "../../../store/events/actions";
import Button from "@mui/material/Button";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import {ResultSorting} from "./SortAndColumns/ResultSorting";
import { ResultColumns } from "./SortAndColumns/ResultColumns";
function EventFileResultsAttendee({ data }) {
  console.log("expandable comp", data);

  const [resultList, setResultList] = useState();
  
 
  useEffect(() => {
    let clubResults = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        clubResults.push(...data[i].attendees);
      }

      // let sorted = customSort({
      //   data: tempArray,
      //   sortBy: sortBy3,
      //   sortField: "class",
      // });

      // let sorted2 =customSort({ data: sorted1, sortBy:sortBy2, sortField: "level" })
      //let sorted =customSort({ data: sorted2, sortBy:sortBy1, sortField: "division" })
let sorted = ResultSorting(clubResults,data[0].event_category);
      setResultList(sorted);
      console.log("originial results", clubResults.length);
    }
  }, [data]);


  return (
    <div style={{ background: "#f00" }}>
      <ToastContainer />
      <Card className="my-2" style={{ padding: 10 }}>
        <DataTable
          columns={ResultColumns(data[0].event_category)}
          data={resultList}
          highlightOnHover
          className="publicRankingTable"
          sortIcon={<FilterListIcon />}
        />
      </Card>
    </div>
  );
}

export default EventFileResultsAttendee;
