import DataTable, { createTheme } from 'react-data-table-component';
import React, { useMemo } from 'react';
import {  Row, Col } from 'reactstrap';
import logo from '../assets/ckc-logo.png';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getEventPublicResult } from '../store/events/actions';
import { Circles } from "react-loader-spinner";
import ContentLoader from "react-content-loader";

export const Listing = () => {

 const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const eventType = searchParams.get("type");

  const { publicRankingList, loadingGetEventPublicResult,  } = useSelector((state) => state.EventsReducer);

 
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventPublicResult(eventId));

  }, []);

  const columnsAgility = useMemo(() => [

    {
      name: 'Dog Name & Breed',
      sortable: true,
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name'>{row.dog_title}</p>
        <p className='breed_name'>{row.breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Ranking',
      selector: row => row.rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    
    {
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Av. Score',
      selector: row => row.average_score,
      center: true,
      sortable: true,
      wrap: true,
    },

  ], []);

  const columns = useMemo(() => [

    {
      name: 'Dog Name & Breed',
      sortable: true,
      wrap: true,
      grow: 3,
      cell: row => (<Row>
        <p className='dog_name'>{row.dog_title}</p>
        <p className='breed_name'>{row.breed}</p>
      </Row>

      ),
    },
    {
      name: 'Owner Name',
      selector: row => row.owner_name,
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Ranking',
      selector: row => row.rank,
      sortable: true,
      center: true,
      wrap: true,
    },
    
    {
      name: 'Points',
      center: true,
      selector: row => row.total_points,
      sortable: true,
      wrap: true,
    },
    

  ], []);

  return (

    publicRankingList.length>0?(
    <div className='listing-page-body'>
      <div className='topHeader'>
        <div className='leftSide'>
          <p className='ckc-name'>Canadian Kennel Club Top Dogs</p>
          <img src={logo} alt="Logo" className='logo' />
          <p className='divider-line'></p>
          <div className='event-details'>
            <p className='event-heading'>{eventType}</p>
            <p className='event-date'>From {publicRankingList[0].attendees.start_date} to {publicRankingList[0].attendees.end_date}</p>

          </div>
        </div>
      </div>

      <div
        style={{ paddingLeft: '120px', paddingRight: '120px', paddingTop: '20px' }}

      >
        <p className='top-dog-stand-head'>Top Dog Standings</p>
{/* 
        <div>

          <input
            className='search-field1'
            type='text'
            placeholder='Search by dog name, breed, owner name' />

          <input
            className='search-field2'
            type='text'
            placeholder='Event Type' />

          <input
            className='search-field2'
            type='text'
            placeholder='Ranking' />
          <button className='search-button'> Search</button>

        </div> */}
        <div className='divider-line1' />
        <DataTable
          columns={eventType==="Agility"?(columnsAgility):(columns)}
          data={publicRankingList}
          theme=""
          className='publicRankingTable'
          highlightOnHover
          defaultSortAsc={false}
        />
      </div>

    </div>
    ):(
      <div className="loader" >
        {loadingGetEventPublicResult ? (
          <ContentLoader 
            speed={1}
            width={'100%'}
            height={(70 + 3) * 10 - 4} // Adjust the height based on the number of lines and gap
            viewBox={`0 0 100% ${(70 + 4) * 10 - 4}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            {Array.from({ length: 10 }, (_, index) => (
              <rect
                key={index}
                x="0"
                y={(70 + 4) * index}
                rx="5"
                ry="5"
                width="100%"
                height="70"
              />
            ))}
          </ContentLoader>
        ) : ('')}
    </div>
    )
  );

};
